import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  isDisabled?: boolean;
  className?: string;
}

const ButtonSubmit: FC<Props> = ({
  href = "/listing-flights",
  onClick,
  isDisabled = false,
  className = "",
}) => {
  const buttonContent = (
    <span className="flex items-center justify-center">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 mr-2" // İkon ile metin arasında boşluk
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg> */}
      Arama Yap
    </span>
  );

  const buttonClasses = `w-1/2 flex justify-center text-sm font-medium p-4 rounded-2xl shadow-lg ${
    isDisabled
      ? "bg-gray-400 text-white cursor-not-allowed"
      : "bg-white dark:bg-neutral-800 text-black dark:text-white dark:hover:bg-[#1e2535] hover:bg-[#fafafa]"
  } ${className}`;

  if (isDisabled) {
    return (
      <Link
        to={href}
        className={buttonClasses}
        aria-disabled="true"
        onClick={(event) => event.preventDefault()}
      >
        {buttonContent}
      </Link>
    );
  }

  return (
    <Link to={href} className={buttonClasses} onClick={onClick}>
      {buttonContent}
    </Link>
  );
};

export default ButtonSubmit;
