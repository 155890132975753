import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  isDisabled?: boolean;
}

const ButtonSubmit: FC<Props> = ({
  href = "/listing-flights",
  onClick,
  isDisabled = false,
}) => {
  const buttonContent = (
    <>
      <span className="mr-3 md:hidden">Search</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </>
  );

  return isDisabled ? (
    <span
      className={`h-14 md:h-16 w-full md:w-16 rounded-full flex items-center justify-center text-neutral-50 bg-gray-400 cursor-not-allowed`}
      aria-disabled="true"
    >
      {buttonContent}
    </span>
  ) : (
    <Link
      to={href}
      type="button"
      className={`h-14 md:h-16 w-full md:w-16 rounded-full flex items-center justify-center text-neutral-50 bg-black hover:bg-[#333333] focus:outline-none dark:hover:bg-[#1a1a1a]`}
      onClick={onClick}
    >
      {buttonContent}
    </Link>
  );
};

export default ButtonSubmit;
