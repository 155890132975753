import React from "react";
import imagePng from "images/logo.png";

const LogoSvg = () => {
  return (
    <img
      src={imagePng}
      alt="Logo"
      className="w-full block dark:hidden"
    />
  );
};

export default LogoSvg;