/* eslint-disable @typescript-eslint/no-unused-vars */
import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState, useEffect, useCallback, useMemo, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import { GuestsObject } from "components/HeroSearchForm/type";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FlightCardProps } from "components/FlightCard/FlightCard";
import Heading2 from "components/Heading/Heading2";
import Select from "shared/Select/Select";
import paymentCardsImage from "images/payment-cards.png";
import useCountdown from "./useCountdown";
import { StayDataType } from "data/types";
import {
  AsYouType,
  getExampleNumber,
  parsePhoneNumber,
  CountryCode,
} from "libphonenumber-js/max";
import examples from "libphonenumber-js/examples.mobile.json";
import Checkbox from "shared/Checkbox/Checkbox";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import GallerySlider from "components/GallerySlider/GallerySlider";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface CheckOutPagePageMainProps {
  className?: string;
  selectedPackage?: string;
}

interface StayData extends StayDataType {
  checkInDate?: string;
  checkOutDate?: string;
  stayDirection?: string;
  stayClass?: string;
  location?: string;
}

interface PaymentResult {
  success: boolean;
  message: string;
}

interface PhoneInputProps {
  countryCode: string;
  value: string;
  placeholder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCountryCodeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error: boolean;
}

const PhoneInput = memo(({ 
  countryCode, 
  value, 
  placeholder, 
  onChange, 
  onCountryCodeChange, 
  error 
}: PhoneInputProps) => {
  return (
    <div className="flex-1 space-y-1">
      <Label>Telefon Numarası</Label>
      <div className="flex relative">
        <select
          value={countryCode}
          onChange={onCountryCodeChange}
          className="absolute left-0 top-0 bottom-0 w-20 bg-transparent border-none outline-none focus:outline-none focus:ring-0 focus:ring-offset-0 focus:shadow-none hover:bg-transparent active:bg-transparent pl-3 pr-8 appearance-none z-10"
        >
          <option value="+90">+90</option>
          <option value="+1">+1</option>
          <option value="+49">+49</option>
          <option value="+44">+44</option>
          <option value="+33">+33</option>
          <option value="+39">+39</option>
          <option value="+34">+34</option>
          <option value="+31">+31</option>
        </select>
        <Input
          type="tel"
          name="contactPhone"
          placeholder={error ? "Lütfen bu alanı doldurun." : placeholder}
          value={value}
          onChange={onChange}
          className={`pl-20 ${error ? "placeholder-red-400" : ""}`}
        />
      </div>
    </div>
  );
});
  const CheckoutStayCard: React.FC<{
    stay: StayDataType;
  }> = ({ stay }) => {
    const renderSliderGallery = () => {
      return (
        <div className="relative w-full">
          <GallerySlider
            uniqueID={`StayCard_${stay.id}`}
            ratioClass="aspect-w-4 aspect-h-3"
            galleryImgs={stay.galleryImgs}
            href={stay.href}
          />
          <BtnLikeIcon isLiked={stay.like} className="absolute right-3 top-3 z-[1]" />
          {stay.saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
        </div>
      );
    };

    const renderContent = () => {
      return (
        <div className="p-4 space-y-4">
          <div className="space-y-2">
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {stay.listingCategory.name} · {stay.bedrooms} beds
            </span>
            <div className="flex items-center space-x-2">
              {stay.isAds && <Badge name="ADS" color="green" />}
              <h2 className="text-lg font-medium capitalize">
                <span className="line-clamp-1">{stay.title}</span>
              </h2>
            </div>
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span className="">{stay.address}</span>
            </div>
          </div>
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex justify-between items-center">
            <span className="text-base font-semibold">
              {stay.price}
              {` `}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            </span>
            {!!stay.reviewStart && (
              <StartRating reviewCount={stay.reviewCount} point={stay.reviewStart} />
            )}
          </div>
        </div>
      );
    };

    return (
      <div className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow">

{       renderSliderGallery()}

        {renderContent()}
        
      </div>
    );
  };
const StayCheckOutPageMain: FC<CheckOutPagePageMainProps> = ({
  className = "",
  selectedPackage,
}) => {
  const [invoiceType, setInvoiceType] = useState("Bireysel");

  const handleInvoiceTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setInvoiceType(e.target.value);
  };

  const location = useLocation();
  const [selectedFlight, setSelectedFlight] = useState<
    FlightCardProps["data"] | null
  >(null);

  const [gender, setGender] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("Türkiye");

  const [stayData, setStayData] = useState<StayData | null>(null);



  useEffect(() => {
    if (location.state?.stayData) {
      const { userCriteria, ...otherStayData } = location.state.stayData;
      setStayData({
        ...otherStayData,
        userCriteria: userCriteria || {}
      });
      console.log("Otel Bilgileri güncellendi:", location.state.stayData);
    } else {
      console.log("Otel verisi bulunamadı");
    }
  }, [location.state]);

  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});

  const [selectedStay, setSelectedStay] = useState<StayDataType | null>(null);
    const [selectedReturnFlight, setSelectedReturnFlight] = useState<
    FlightCardProps["data"] | null
  >(null);

  useEffect(() => {
    if (location.state) {
      const { selectedStay } = location.state;
      setSelectedStay(selectedStay);
    }
  }, [location.state]);

  const getLocationWithoutParentheses = (location: string | undefined) => {
    return location ? location.replace(/\([^)]*\)/g, '').trim() : '';
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState<{ [key: string]: string }>({});


  useEffect(() => {
    const initialFormData: { [key: string]: string } = {};
    const passengerCount =
      stayData?.maxGuests || 0;

    for (let i = 0; i < passengerCount; i++) {
      initialFormData[`passengerNationality${i}`] = "Türkiye";
    }

    setFormData(initialFormData);
  }, [stayData]);

  const [cardInfo, setCardInfo] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  const [cardErrors, setCardErrors] = useState({
    cardHolder: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });

  

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const handleTermsChange = (checked: boolean) => {
    setIsTermsAccepted(checked);
  };


  const isOnlyLetters = (str: string) => /^[A-Za-zğüşıöçĞÜŞİÖÇ\s]+$/.test(str);

  const handleCardInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value;
    let error = "";

    switch (name) {
      case "cardHolder":
        formattedValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, "");
        error = formattedValue
          ? isOnlyLetters(formattedValue)
            ? ""
            : ""
          : "Lütfen geçerli bir kart sahibi adı ve soyadı girin.";
        break;
      case "cardNumber":
        formattedValue = value
          .replace(/\D/g, "")
          .replace(/(\d{4})/g, "$1 ")
          .trim();
        formattedValue = formattedValue.substring(0, 19);
        error =
          formattedValue.replace(/\s/g, "").length === 16
            ? ""
            : "Lütfen geçerli bir kart numarası girin.";
        break;
      case "expiryDate":
        if (
          /^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate) &&
          value.length > cardInfo.expiryDate.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "");
        if (formattedValue.length > 2) {
          formattedValue =
            formattedValue.substring(0, 2) +
            "/" +
            formattedValue.substring(2, 4);
        }
        error = /^(0[1-9]|1[0-2])\/\d{2}$/.test(formattedValue)
          ? ""
          : "Lütfen geçerli bir son kullanma tarihi girin.";
        break;
      case "cvv":
        if (
          (cardInfo.cvv.length === 3 || cardInfo.cvv.length === 4) &&
          value.length > cardInfo.cvv.length
        ) {
          return;
        }
        formattedValue = value.replace(/\D/g, "").substring(0, 4);
        error =
          formattedValue.length >= 3 && formattedValue.length <= 4
            ? ""
            : "Lütfen geçerli bir CVV girin.";
        break;
    }

    setCardInfo((prev) => ({ ...prev, [name]: formattedValue }));
    setCardErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isValidTCKN = (value: string) => /^[0-9]{11}$/.test(value);

  const isValidEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return re.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    let newValue = value;

    if ((name.includes('Name') && !name.includes('companyName')) || name.includes('Surname') || name === 'invoiceCity' || name === 'invoiceDistrict' || name === 'taxOffice') {
      newValue = value.replace(/[^A-Za-zğüşıöçĞÜŞİÖÇ\s]/g, '');
    } else if (name.includes('BirthDate') || name.includes('PassportExpiry')) {
      newValue = value.replace(/[^\d/]/g, '');
      if (newValue.length === 2 || newValue.length === 5) {
        if (newValue.charAt(newValue.length - 1) !== '/') {
          newValue += '/';
        }
      }
      newValue = newValue.slice(0, 10);
    } else if (name.includes('IdentityNumber')) {
      newValue = value.replace(/\D/g, '').slice(0, 11);
    } else if (name.includes('PassportNumber')) {
      newValue = value.replace(/\D/g, '').slice(0, 9);
    } else if (name === 'contactPhone' || name === 'taxNumber') {
      newValue = value.replace(/\D/g, '');
      if (name === 'taxNumber') {
        newValue = newValue.slice(0, 10);
      }
    } else if (name === 'contactEmail') {
      newValue = value.toLowerCase();
    } else if (name === 'companyName') {
      newValue = value;
    }

    setFormData(prev => {
      const updatedForm = { ...prev, [name]: newValue };
      
      if (name.includes('Nationality')) {
        const index = name.replace('passengerNationality', '');
        if (newValue !== 'Türkiye') {
          delete updatedForm[`passengerIdentityNumber${index}`];
          updatedForm[`passengerPassportNumber${index}`] = updatedForm[`passengerPassportNumber${index}`] || '';
          updatedForm[`passengerPassportExpiry${index}`] = updatedForm[`passengerPassportExpiry${index}`] || '';
        } else {
          delete updatedForm[`passengerPassportNumber${index}`];
          delete updatedForm[`passengerPassportExpiry${index}`];
          updatedForm[`passengerIdentityNumber${index}`] = updatedForm[`passengerIdentityNumber${index}`] || '';
        }
      }
      
      return updatedForm;
    });
    
    if (newValue) {
      setFormErrors(prev => ({ ...prev, [name]: false }));
    }
  };

  const [countryCode, setCountryCode] = useState<string>("+90");
  const [placeholder, setPlaceholder] = useState<string>("5XX XXX XX XX");

  const phonePlaceholders: { [key: string]: string } = {
    "+90": "5XX XXX XX XX",
    "+1": "(XXX) XXX-XXXX",
    "+49": "XXXX XXXXXXX",
    "+44": "XXXX XXXXXX",
    "+33": "X XX XX XX XX",
    "+39": "XXX XXX XXXX",
    "+34": "XXX XX XX XX",
    "+31": "X XX XX XX XX",
  };

  const updatePlaceholder = useCallback(() => {
    const newPlaceholder = phonePlaceholders[countryCode] || "XXX XXX XXXX";
    console.log("New placeholder:", newPlaceholder);
    setPlaceholder(newPlaceholder);
  }, [countryCode]);
  
  useEffect(() => {
    updatePlaceholder();
  }, [countryCode]);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const formatter = new AsYouType(countryCode.slice(1) as CountryCode);
    const formattedNumber = formatter.input(value);

    setFormData((prev) => ({
      ...prev,
      contactPhone: formattedNumber,
    }));
  };

  const validatePhoneNumber = (phoneNumber: string, country: string) => {
    try {
      const fullNumber = country + phoneNumber.replace(/\D/g, "");
      const parsedNumber = parsePhoneNumber(fullNumber);
      return parsedNumber.isValid();
    } catch (error) {
      return false;
    }
  };

  const handleCountryCodeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCountryCode = e.target.value;
    console.log("Country code changed to:", newCountryCode);
    setCountryCode(newCountryCode);
    setFormData(prev => ({ ...prev, contactPhone: "" }));
  }, []);

  const phoneInputProps: PhoneInputProps = useMemo(() => ({
    countryCode,
    value: formData.contactPhone || "",
    placeholder: phonePlaceholders[countryCode] || "XXX XXX XXXX",
    onChange: handleInputChange,
    onCountryCodeChange: handleCountryCodeChange,
    error: !!formErrors.contactPhone
  }), [countryCode, formData.contactPhone, formErrors.contactPhone, handleInputChange, handleCountryCodeChange]);

  const validateForm = () => {
    console.log("Form doğrulama başlatıldı");
    const newErrors: { [key: string]: boolean } = {};
    const newCardErrors = { ...cardErrors };
    let isValid = true;

    const passengerCount =
      stayData?.maxGuests || 0;

    for (let i = 0; i < passengerCount; i++) {
      ["Name", "Surname", "BirthDate", "Gender", "Nationality"].forEach(
        (field) => {
          const key = `passenger${field}${i}`;
          if (!formData[key] || formData[key].trim() === "") {
            newErrors[key] = true;
            isValid = false;
          }
        }
      );

      if (formData[`passengerNationality${i}`] === "Türkiye") {
        if (
          !formData[`passengerIdentityNumber${i}`] ||
          formData[`passengerIdentityNumber${i}`].length !== 11
        ) {
          newErrors[`passengerIdentityNumber${i}`] = true;
          isValid = false;
        }
        delete formData[`passengerPassportNumber${i}`];
        delete formData[`passengerPassportExpiry${i}`];
      } else {
        if (
          !formData[`passengerPassportNumber${i}`] ||
          formData[`passengerPassportNumber${i}`].length !== 9
        ) {
          newErrors[`passengerPassportNumber${i}`] = true;
          isValid = false;
        }
        if (
          !formData[`passengerPassportExpiry${i}`] ||
          formData[`passengerPassportExpiry${i}`].length !== 10
        ) {
          newErrors[`passengerPassportExpiry${i}`] = true;
          isValid = false;
        }
        delete formData[`passengerIdentityNumber${i}`];
      }
    }


    ["contactName", "contactSurname", "contactPhone", "contactEmail"].forEach(
      (key) => {
        if (!formData[key]) {
          newErrors[key] = true;
          isValid = false;
        }
      }
    );


    ["invoiceCity", "invoiceDistrict", "invoiceAddress"].forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = true;
        isValid = false;
      }
    });


    if (!isValidEmail(formData.contactEmail)) {
      newErrors.contactEmail = true;
      isValid = false;
    }


    if (formData.invoiceType === "Kurumsal") {
      if (!formData.taxNumber || formData.taxNumber.length !== 10) {
        newErrors.taxNumber = true;
        isValid = false;
      }
      if (!formData.companyName) {
        newErrors.companyName = true;
        isValid = false;
      }
      if (!formData.taxOffice) {
        newErrors.taxOffice = true;
        isValid = false;
      }
    }


    ["invoiceCity", "invoiceDistrict", "taxOffice"].forEach((field) => {
      if (formData[field] && !isOnlyLetters(formData[field])) {
        newErrors[field] = true;
        isValid = false;
      }
    });

    if (formData.contactPhone) {
      if (!validatePhoneNumber(formData.contactPhone, countryCode)) {
        newErrors.contactPhone = true;
        isValid = false;
      }
    } else {
      newErrors.contactPhone = true;
      isValid = false;
    }


    Object.keys(cardInfo).forEach((field) => {
      if (!cardInfo[field as keyof typeof cardInfo].trim()) {
        newErrors[field] = true;
        newCardErrors[
          field as keyof typeof cardErrors
        ] = `Lütfen bu alanı doldurun.`;
        isValid = false;
      } else {
        newCardErrors[field as keyof typeof cardErrors] = "";
      }
    });


    if (cardInfo.cardNumber.replace(/\s/g, "").length !== 16) {
      newErrors.cardNumber = true;
      newCardErrors.cardNumber = "Lütfen bu alanı doldurun.";
      isValid = false;
    }


    if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(cardInfo.expiryDate)) {
      newErrors.expiryDate = true;
      newCardErrors.expiryDate = "Lütfen bu alanı doldurun.";
      isValid = false;
    }


    if (cardInfo.cvv.length < 3 || cardInfo.cvv.length > 4) {
      newErrors.cvv = true;
      newCardErrors.cvv = "Lütfen bu alanı doldurun.";
      isValid = false;
    }

    if (!isTermsAccepted) {
      newErrors.terms = true;
      isValid = false;
    }

    setFormErrors(newErrors);
    setCardErrors(newCardErrors);
    console.log("Form doğrulama sonucu:", isValid);
    console.log("Form hataları:", newErrors);
    return isValid;
  };

  const [showErrors, setShowErrors] = useState(false);

  const simulatePayment = async (): Promise<PaymentResult> => {
    console.log("Ödeme simülasyonu başlatılıyor");
    return new Promise((resolve) => {
      setTimeout(() => {
        const success = Math.random() > 0.2;
        console.log("Ödeme simülasyonu sonucu:", success ? "Başarılı" : "Başarısız");
        resolve({ success, message: success ? "Ödeme başarılı" : "Ödeme başarısız" });
      }, 2000);
    });
  };

  const [generalError, setGeneralError] = useState<string | null>(null);

  const handlePayment = async () => {
    console.log("Ödeme işlemi başlatılıyor");
    if (isTimeoutOccurred) {
      console.log("Rezervasyon süresi doldu, modal gösteriliyor");
      setShowTimeoutModal(true);
    } else {
      setShowErrors(true);
      console.log("Form doğrulaması başlatılıyor");
      if (validateForm()) {
        try {
          console.log("Form doğrulaması başarılı, ödeme simülasyonu başlatılıyor");
          const paymentResult = await simulatePayment();
          console.log("Ödeme simülasyonu sonucu:", paymentResult);
          if (paymentResult.success) {
            const organizedData = organizeFormData(formData);
            organizedData.paymentResult = paymentResult;
            console.log("Ödeme başarılı, pay-done sayfasına yönlendiriliyor. Gönderilen veri:", organizedData);
            navigate("/stay-pay-done", { state: { formData: organizedData,stayData: stayData } });
          } else {
            console.log("Ödeme başarısız:", paymentResult.message);
            setGeneralError(paymentResult.message);
          }
        } catch (error) {
          console.error("Ödeme işlemi sırasında hata:", error);
          setGeneralError("Ödeme işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
        }
      } else {
        console.log("Form doğrulaması başarısız");
      }
    }
  };

  const renderInput = (name: string, label: string, placeholder: string) => (
    <div className="flex-1 space-y-1">
      <Label>{label}</Label>
      <Input
        type="text"
        name={name}
        placeholder={
          formErrors[name] ? "Lütfen bu alanı doldurun." : placeholder
        }
        value={formData[name] || ""}
        onChange={handleInputChange}
        className={formErrors[name] ? "placeholder-red-400" : ""}
      />
    </div>
  );


  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split(".");
    return [integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, "."), decimalPart];
  };


  const calculateTotalPrice = () => {
    if (stayData) {
      const pricePerNight = parseFloat(stayData.price.replace(/[^0-9.-]+/g, "."));
      const nights = calculateNights(stayData.userCriteria?.stayDates?.startDate, stayData.userCriteria?.stayDates?.endDate) || 1;
      return pricePerNight * nights;
    }
    return 0;
  };

  const calculateNights = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const { minutes, seconds } = useCountdown(15, 0);
  const [isTimeoutOccurred, setIsTimeoutOccurred] = useState(false);

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      setShowTimeoutModal(true);
      setIsTimeoutOccurred(true);
    }
  }, [minutes, seconds]);

  const handleSearchAgainMain = () => {
    setShowTimeoutModal(false);
    navigate("/");
  };


  const renderTimeoutModal = () => (
    <NcModal
      renderTrigger={() => null} 
      isOpenProp={showTimeoutModal}
      onCloseModal={() => setShowTimeoutModal(false)}
      modalTitle="Rezervasyon Bilgileri"
      contentExtraClass="max-w-screen-lg"
      renderContent={() => (
        <div className="text-md text-neutral-500 space-y-4">
        <span className="text-justify">
        TODOGO bu odayı sizin için ayırmıştı ancak size ayırılan süre
            içerisinde ödeme yapılmadığı için rezervasyonunuz iptal edildi. {" "} Üzgünüz, ancak{" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
              burayı
            </span>
            {" "}
            kullanarak{" "}
            
            yeniden 
            {" "}
            <span
              className="cursor-pointer text-secondary-6000"
              onClick={handleSearchAgainMain}
            >
               arama {" "}
            </span>
            
            yapabilirsiniz. Merak etmeyin, sizin için burada olacağız.
          </span>
        </div>
      )}
    />
  );

  const renderSidebar = () => {
    const totalPrice = calculateTotalPrice();
    const [integerPart, decimalPart] = formatPrice(totalPrice);

    return (
      <div className="sticky top-32">
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
          <h3 className="text-2xl font-semibold">Ödeme Bilgileri</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>


          <div className="space-y-6">
            <div className="flex space-x-4">
              <img
                src={paymentCardsImage}
                alt="Kabul edilen ödeme yöntemleri"
                className="h-8 object-contain"
              />
            </div>
          </div>


          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="flex-1">
                <Label>Kart Sahibi</Label>
                <Input
                  type="text"
                  name="cardHolder"
                  value={cardInfo.cardHolder}
                  onChange={handleCardInputChange}
                  placeholder="Ad Soyad"
                  className={`mt-1 ${cardErrors.cardHolder ? "" : ""}`}
                />
                {cardErrors.cardHolder && (
                  <p className="text-red-400 mt-1 w-full"
                  style={{fontSize: "13px"}}
                  >
                    {cardErrors.cardHolder}
                  </p>
                )}
              </div>
              <div className="flex-1">
                <Label>Kart Numarası</Label>
                <Input
                  type="text"
                  name="cardNumber"
                  value={cardInfo.cardNumber}
                  onChange={handleCardInputChange}
                  placeholder="**** **** **** ****"
                  className={`mt-1 ${cardErrors.cardNumber ? "" : ""}`}
                />
                {cardErrors.cardNumber && (
                  <p className="text-red-400 mt-1 w-full"
                  style={{fontSize: "13px"}}
                  >
                    {cardErrors.cardNumber}
                  </p>
                )}
              </div>
            </div>

            <div className="flex space-x-4">
              <div className="flex-1">
                <Label>Son Kullanma Tarihi</Label>
                <Input
                  type="text"
                  name="expiryDate"
                  value={cardInfo.expiryDate}
                  onChange={handleCardInputChange}
                  placeholder="AA/YY"
                  className={`mt-1 ${cardErrors.expiryDate ? "" : ""}`}
                />
                {cardErrors.expiryDate && (
                  <p className="text-red-400 mt-1 w-full"
                  style={{fontSize: "13px"}}
                  >
                    {cardErrors.expiryDate}
                  </p>
                )}
              </div>
              <div className="flex-1">
                <Label>CVV</Label>
                <Input
                  type="text"
                  name="cvv"
                  value={cardInfo.cvv}
                  onChange={handleCardInputChange}
                  placeholder="CVV"
                  className={`mt-1 ${cardErrors.cvv ? "" : ""}`}
                />
                {cardErrors.cvv && (
                  <p className="text-red-400 mt-1 w-full"
                  style={{fontSize: "13px"}}
                  >
                    {cardErrors.cvv}
                  </p>
                )}
              </div>
            </div>
          </div>


          <div className="flex items-start space-x-3">
            <Checkbox
              name="terms"
              label=""
              className="mt-1 flex-shrink-0"
              defaultChecked={isTermsAccepted}
              onChange={handleTermsChange}
            />
            <p
              className={`text-sm text-neutral-500 ${
                showErrors && !isTermsAccepted ? "text-red-400" : ""
              }`}
            >
              Kişisel verilerin işlenmesine ilişkin{" "}
              <NcModal
                className="inline"
                modalTitle="Aydınlatma Metni"
                contentExtraClass="max-w-screen-lg"
                renderTrigger={(openModal) => (
                  <span
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      openModal();
                    }}
                    className="underline cursor-pointer text-neutral-500"
                  >
                    Aydınlatma Metni'ni
                  </span>
                )}
                renderContent={() => (
                  <div className="text-md text-neutral-500 space-y-4">
                  <p className="text-justify">
                    Proident laborum officia ipsum do. Dolor ex culpa
                    nostrud proident laborum ut aliqua pariatur proident ut
                    pariatur qui ullamco Lorem. Deserunt consequat culpa
                    magna adipisicing sunt laborum. Aute Lorem ex dolore
                    magna anim sint duis anim esse nostrud ea.
                  </p>
                  <p className="text-justify">
                    Esse officia proident sunt ex cupidatat proident nisi
                    dolore dolor anim pariatur sint. Laboris excepteur est
                    dolor laboris est exercitation consequat in velit.
                    Occaecat aute exercitation in non officia consequat
                    occaecat duis ullamco consequat incididunt eiusmod.
                    Labore enim consequat fugiat laboris ullamco id
                    reprehenderit ipsum ad. Reprehenderit reprehenderit
                    deserunt fugiat adipisicing aliquip eiusmod quis.
                  </p>
                  <p className="text-jusify">
                    Veniam consectetur pariatur fugiat eiusmod. Excepteur
                    exercitation tempor minim elit aliqua eu eiusmod qui
                    magna deserunt nostrud Lorem labore. Velit duis occaecat
                    pariatur eu enim consequat eu laborum anim cupidatat
                    dolor deserunt id enim. Adipisicing et veniam duis
                    fugiat nisi nostrud. Nostrud excepteur consectetur
                    labore commodo veniam eu quis reprehenderit adipisicing
                    veniam.
                  </p>
                </div>
                )}
              />{" "}
              ve{" "}
              <NcModal
                className="inline"
                modalTitle="Çerez Politikası Metni"
                contentExtraClass="max-w-screen-lg"
                renderTrigger={(openModal) => (
                  <span
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      openModal();
                    }}
                    className="underline cursor-pointer text-neutral-500"
                  >
                    Çerez Politikası Metni'ni
                  </span>
                )}
                renderContent={() => (
                  <div className="text-md text-neutral-500 space-y-4">
                  <p className="text-justify">
                    Proident laborum officia ipsum do. Dolor ex culpa
                    nostrud proident laborum ut aliqua pariatur proident ut
                    pariatur qui ullamco Lorem. Deserunt consequat culpa
                    magna adipisicing sunt laborum. Aute Lorem ex dolore
                    magna anim sint duis anim esse nostrud ea.
                  </p>
                  <p className="text-justify">
                    Esse officia proident sunt ex cupidatat proident nisi
                    dolore dolor anim pariatur sint. Laboris excepteur est
                    dolor laboris est exercitation consequat in velit.
                    Occaecat aute exercitation in non officia consequat
                    occaecat duis ullamco consequat incididunt eiusmod.
                    Labore enim consequat fugiat laboris ullamco id
                    reprehenderit ipsum ad. Reprehenderit reprehenderit
                    deserunt fugiat adipisicing aliquip eiusmod quis.
                  </p>
                  <p className="text-jusify">
                    Veniam consectetur pariatur fugiat eiusmod. Excepteur
                    exercitation tempor minim elit aliqua eu eiusmod qui
                    magna deserunt nostrud Lorem labore. Velit duis occaecat
                    pariatur eu enim consequat eu laborum anim cupidatat
                    dolor deserunt id enim. Adipisicing et veniam duis
                    fugiat nisi nostrud. Nostrud excepteur consectetur
                    labore commodo veniam eu quis reprehenderit adipisicing
                    veniam.
                  </p>
                </div>
                )}
              />{" "}
              okudum.{" "}
              <NcModal
                className="inline"
                modalTitle="Kullanım Koşulları"
                contentExtraClass="max-w-screen-lg"
                renderTrigger={(openModal) => (
                  <span
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      openModal();
                    }}
                    className="underline cursor-pointer text-neutral-500"
                  >
                    Kullanım Koşulları'nı
                  </span>
                )}
                renderContent={() => (
                  <div>
                    <div className="text-md text-neutral-500 space-y-4">
                      <p className="text-justify">
                        Proident laborum officia ipsum do. Dolor ex culpa
                        nostrud proident laborum ut aliqua pariatur proident ut
                        pariatur qui ullamco Lorem. Deserunt consequat culpa
                        magna adipisicing sunt laborum. Aute Lorem ex dolore
                        magna anim sint duis anim esse nostrud ea.
                      </p>
                      <p className="text-justify">
                        Esse officia proident sunt ex cupidatat proident nisi
                        dolore dolor anim pariatur sint. Laboris excepteur est
                        dolor laboris est exercitation consequat in velit.
                        Occaecat aute exercitation in non officia consequat
                        occaecat duis ullamco consequat incididunt eiusmod.
                        Labore enim consequat fugiat laboris ullamco id
                        reprehenderit ipsum ad. Reprehenderit reprehenderit
                        deserunt fugiat adipisicing aliquip eiusmod quis.
                      </p>
                      <p className="text-jusify">
                        Veniam consectetur pariatur fugiat eiusmod. Excepteur
                        exercitation tempor minim elit aliqua eu eiusmod qui
                        magna deserunt nostrud Lorem labore. Velit duis occaecat
                        pariatur eu enim consequat eu laborum anim cupidatat
                        dolor deserunt id enim. Adipisicing et veniam duis
                        fugiat nisi nostrud. Nostrud excepteur consectetur
                        labore commodo veniam eu quis reprehenderit adipisicing
                        veniam.
                      </p>
                    </div>
                  </div>
                )}
              />{" "}
              kabul ediyorum.
            </p>
          </div>



          <div className="space-y-4">
            <ButtonSecondary
              onClick={handlePayment}
              className="w-full hover:bg-neutral-50 dark:hover:bg-black/20"
            >
              <div className="flex items-center justify-center space-x-2">
                <span className="text-xl font-semibold text-secondary-6000 break-normal">
                  {integerPart}
                  <span>
                    <span className="text-sm">,{decimalPart}</span> TL
                  </span>
                </span>
                <i className="text-xl las la-angle-right text-secondary-6000"></i>
              </div>
            </ButtonSecondary>
            <p className="text-sm text-neutral-500 text-center">
              TODOGO üzerinden yapılan işlemler güvenlik sertifikalarıyla
              korunmaktadır.
              <i className="text-2xl las la-user-shield ml-1 text-secondary-6000"></i>
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 sticky mt-10">
          <h3 className="text-2xl font-semibold">Rezervasyon Bilgileri</h3>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="space-y-4">
          <ButtonSecondary 
  className="w-full cursor-default" 
  disabled={true}
>
  <div className="flex items-center justify-center space-x-2">
    <span className="text-xl font-semibold text-secondary-6000 break-normal">
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </span>
  </div>
</ButtonSecondary>
            <p className="text-sm text-neutral-500 text-center">
              TODOGO bu odayı sizin için ayırdı. Lütfen size ayırılmış süre
              içerisinde ödeme yapınız.
              <i className="text-2xl las la-user-clock ml-1 text-secondary-6000"></i>
            </p>
          </div>
        </div>
      </div>
    );
  };

  

  const renderGuestInfo = () => {
    const totalRooms = stayData?.userCriteria?.stayGuests?.totalRooms || 1;
    const totalGuests = stayData?.userCriteria?.stayGuests?.totalGuests || stayData?.maxGuests || 1;
    const guestsPerRoom = Math.ceil(totalGuests / totalRooms);
  
    return (
      <div>
        <h3 className="text-2xl font-semibold">Misafir Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        {Array.from({ length: totalRooms }, (_, roomIndex) => (
          <div key={roomIndex} className="mb-5">
            {Array.from({ length: guestsPerRoom }, (_, guestIndex) => {
              const overallGuestIndex = roomIndex * guestsPerRoom + guestIndex;
              if (overallGuestIndex >= totalGuests) return null;
              return (
                <div key={guestIndex} className="mb-5">
                  <h5 className="text-lg font-medium mb-5 mt-7">{roomIndex + 1}. Oda, {guestIndex + 1}. Misafir</h5>
                  <div className="space-y-5">
                    <div className="flex space-x-5">
                      {renderInput(`room${roomIndex}Guest${guestIndex}Name`, "Ad", "Ad")}
                      {renderInput(`room${roomIndex}Guest${guestIndex}Surname`, "Soyad", "Soyad")}
                    </div>
                    <div className="flex space-x-5">
                      {renderInput(`room${roomIndex}Guest${guestIndex}Email`, "E-posta", "E-posta")}
                      {renderInput(`room${roomIndex}Guest${guestIndex}Phone`, "Telefon", "Telefon")}
                    </div>
                  </div>
                </div>
              );
            })}
            {roomIndex < totalRooms - 1 && (
              <div className="border-b border-neutral-200 dark:border-neutral-700 my-8"></div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderContactInfo = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">İletişim Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        <div className="mb-1">
          <div className="space-y-5">
            <div className="flex space-x-5">
              {renderInput("contactName", "Ad", "Ad")}
              {renderInput("contactSurname", "Soyad", "Soyad")}
            </div>
            <div className="flex space-x-5">
              <PhoneInput {...phoneInputProps} />
              {renderInput("contactEmail", "E-posta Adresi", "E-posta Adresi")}
            </div>
            <div className="text-sm text-neutral-500">
            Otel bilgileriniz ve konaklama detaylarınız, ücretsiz SMS ve e-posta ile tarafınıza iletilecektir.
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderInvoiceInfo = () => {
    return (
      <div>
        <h3 className="text-2xl font-semibold">Fatura Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        <div className="mb-5">
          <div className="space-y-5">
            <div className="flex space-x-5">
              <div className="flex-1 space-y-1">
                <Label>Fatura Tipi</Label>
                <Select
                  name="invoiceType"
                  value={formData.invoiceType || "Bireysel"}
                  onChange={handleInputChange}
                  className="nc-Select block w-full text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900"
                >
                  <option value="Bireysel">Bireysel</option>
                  <option value="Kurumsal">Kurumsal</option>
                </Select>
              </div>
              {renderInput("invoiceCity", "İl", "İl")}
            </div>
            <div className="flex space-x-5">
              {renderInput("invoiceDistrict", "İlçe", "İlçe")}
              {renderInput("invoiceAddress", "Adres", "Adres")}
            </div>
            {formData.invoiceType === "Kurumsal" && (
              <div className="flex space-x-5">
                {renderInput("companyName", "Firma Adı", "Firma Adı")}
                {renderInput("taxOffice", "Vergi Dairesi", "Vergi Dairesi")}
                {renderInput("taxNumber", "Vergi Numarası", "Vergi Numarası")}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h3 className="text-2xl font-semibold">Otel Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>


        <div className="space-y-6">
          {stayData && (
            <>
              <div className="-mb-6">
                <Heading2
                  heading={
                    <div className="flex justify-center text-xl font-medium">
                      {stayData.title}
                    </div>
                  }
                  subHeading={
                    <span className="block items-center text-base text-md text-neutral-500 text-center mt-4">
                      <span>
                        {stayData.userCriteria?.stayDates?.startDate} - {stayData.userCriteria?.stayDates?.endDate}
                      </span>
                      <span className="mx-2">·</span>
                      {stayData.userCriteria?.stayGuests?.totalRooms} Oda
                      <span className="mx-2">·</span>
                      {stayData.userCriteria?.stayGuests?.totalGuests} Misafir
                    </span>
                  }
                />
              </div>

              <CheckoutStayCard stay={stayData as StayDataType} />
            </>
          )}

        </div>


   
        {stayData && renderGuestInfo()}

        {stayData && renderContactInfo()}

        {stayData && renderInvoiceInfo()}

      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPagePageMain ${className}`}>
      <main className="container mt-24 mb-24 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">{renderMain()}</div>
        <div className="w-full lg:w-2/5 xl:w-1/3 lg:mb-0 mb-10">
          {renderSidebar()}
        </div>
      </main>
      {renderTimeoutModal()}
    </div>
  );
};

function organizeFormData(formData: { [key: string]: string }) {
  console.log("Form verisi düzenleniyor");
  const organizedData = {
    passengers: [] as any[],
    contactInfo: {} as any,
    invoiceInfo: {} as any,
    paymentResult: {} as any,
  };

  const passengerCount =
    Math.max(
      ...Object.keys(formData)
        .filter((key) => key.startsWith("passengerName"))
        .map((key) => parseInt(key.replace("passengerName", "")))
    ) + 1;

  for (let i = 0; i < passengerCount; i++) { 
    const identityNumber = formData[`passengerIdentityNumber${i}`];
    console.log(`Passenger ${i} identity number:`, identityNumber);
    organizedData.passengers.push({
      name: formData[`passengerName${i}`] || "",
      surname: formData[`passengerSurname${i}`] || "",
      birthDate: formData[`passengerBirthDate${i}`] || "",
      identityNumber: formData[`passengerIdentityNumber${i}`] || "",
      nationality: formData[`passengerNationality${i}`] || "Türkiye",
      passportNumber: formData[`passengerPassportNumber${i}`] || "",
      passportExpiry: formData[`passengerPassportExpiry${i}`] || "",
      gender: formData[`passengerGender${i}`] || "",
    });
  }

  
  organizedData.contactInfo = {
    name: formData.contactName || "",
    surname: formData.contactSurname || "",
    phone: formData.countryCode ? `${formData.countryCode}${formData.contactPhone}` : formData.contactPhone,
    email: formData.contactEmail || "",
  };


  organizedData.invoiceInfo = {
    type: formData.invoiceType || "Bireysel",
    city: formData.invoiceCity || "",
    district: formData.invoiceDistrict || "",
    address: formData.invoiceAddress || "",
    companyName: formData.companyName || "",
    taxOffice: formData.taxOffice || "",
    taxNumber: formData.taxNumber || "",
  };

  console.log("Düzenlenmiş form verisi:", organizedData);
  return organizedData;
}

export default StayCheckOutPageMain;
