import React, { useEffect, useState } from "react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface GuestsObject {
  adults: number;
  childrens: number;
}

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  autoFocus?: boolean;
  hasButtonSubmit?: boolean;
  onSubmit?: (event: React.FormEvent) => void;
  onGuestsChange?: (guests: GuestsObject[]) => void;
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding--small ]",
  className = "",
  autoFocus = false,
  hasButtonSubmit = true,
  onSubmit,
  onGuestsChange,
}) => {
  const [rooms, setRooms] = useState<GuestsObject[]>([
    { adults: 1, childrens: 0 },
  ]);

  const handleChangeData = (
    roomIndex: number,
    value: number,
    type: keyof GuestsObject
  ) => {
    const newRooms = [...rooms];
    newRooms[roomIndex] = {
      ...newRooms[roomIndex],
      [type]: value,
    };
    setRooms(newRooms);
    onGuestsChange?.(newRooms);
  };

  const handleAddRoom = () => {
    if (rooms.length < 7) {
      const newRooms = [...rooms, { adults: 1, childrens: 0 }];
      setRooms(newRooms);
      onGuestsChange?.(newRooms);
    }
  };

  const handleRemoveRoom = (roomIndex: number) => {
    if (rooms.length > 1) {
      const newRooms = rooms.filter((_, index) => index !== roomIndex);
      setRooms(newRooms);
      onGuestsChange?.(newRooms);
    }
  };

  const totalGuests = rooms.reduce(
    (total, room) => total + room.adults + room.childrens,
    0
  );

  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(autoFocus);
  }, [autoFocus]);

  return (
    <div className={`flex z-10 relative ${className}`} ref={refContainer}>
      <div
        className={`flex z-10 relative flex-1 text-left justify-between items-center focus:outline-none cursor-pointer ${
          isOpen ? "nc-hero-field-focused--2" : ""
        }`}
      >
        <div
          className={`${fieldClassName} flex-1`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex-1 text-left">
            <span className="block font-semibold">
              {rooms.length} Oda, {totalGuests} Misafir
            </span>
            <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
              Misafirler
            </span>
          </div>
        </div>
        <div className="relative">
          {!!totalGuests && isOpen && (
            <ClearDataButton
              onClick={() => {
                setRooms([{ adults: 1, childrens: 0 }]);
                onGuestsChange?.([{ adults: 1, childrens: 0 }]);
              }}
            />
          )}
        </div>
        {hasButtonSubmit && (
          <div className="pr-2 xl:pr-4">
            <ButtonSubmit
              href="/listing-flights"
              onClick={onSubmit}
              isDisabled={true}
            />
          </div>
        )}
      </div>

      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
      )}

      {isOpen && (
        <div className="absolute right-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
          {rooms.map((room, index) => (
            <div key={index} className="mb-4">
              <h4 className="flex justify-between items-center font-semibold">
                {index + 1}. Oda
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemoveRoom(index)}
                    className="text-xs text-neutral-500 dark:text-neutral-400 font-normal"
                  >
                    Oda Kaldır
                  </button>
                )}
              </h4>
              <NcInputNumber
                className="w-full mt-4"
                defaultValue={room.adults}
                onChange={(value) => handleChangeData(index, value, "adults")}
                max={7}
                min={1}
                label="Yetişkin"
                desc="17 yaş ve üzeri"
              />
              <NcInputNumber
                className="w-full mt-4"
                defaultValue={room.childrens}
                onChange={(value) => handleChangeData(index, value, "childrens")}
                max={7}
                label="Çocuk"
                desc="0 ve 17 yaş arası"
              />
              {index < rooms.length - 1 && (
                <div className="border-t border-neutral-400 dark:border-neutral-500 my-4" />
              )}
            </div>
          ))}
          {rooms.length < 7 && (
            <button
              type="button"
              onClick={handleAddRoom}
              className="mt-4 text-xs text-neutral-500 dark:text-neutral-400 font-normal"
            >
              Oda Ekle
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GuestsInput;
