import { FC, useEffect, useState } from "react";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import btnIosPng from "images/btn-ios.png";
import btnAndroidPng from "images/btn-android.png";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [isFromApp, setIsFromApp] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Örnek: WebView User-Agent bilgisi içeriyorsa kontrol
    if (userAgent.includes("wv") || userAgent.includes("ReactNative")) {
      setIsFromApp(true);
    }
  }, []);

  return (
    <>
    {!isFromApp && (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl text-center">Todogo.com Artık Cebinizde!</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400 text-center">
        IOS ve Android uygulamalarımızla tatil planlarınızı her an, her yerde kolayca yapın. Unutulmaz deneyimler sadece bir dokunuş uzağınızda!
        </span>
        <div className="flex space-x-3 sm:mt-0 justify-center">
          <a href="/" target="_blank" rel="noopener noreferrer" className="w-40 h-40 sm:w-40 sm:h-40">
            <img src={btnIosPng} alt="iOS Button" className="w-full h-full object-contain" />
          </a>
          {/* <a className="w-16 h-16 sm:w-16 sm:h-16 block dark:hidden mt-8" href="##" target="_blank" rel="noopener noreferrer">
            <img src={qrCode} alt="QR Code" className="w-full h-full object-contain" />
          </a>
          <a className="w-16 h-16 sm:w-16 sm:h-16 hidden dark:block mt-8" href="##" target="_blank" rel="noopener noreferrer">
            <img src={qrCodeLight} alt="QR Code Light" className="w-full h-full object-contain" />
          </a> */}
          <a href="/" target="_blank" rel="noopener noreferrer" className="w-40 h-40 sm:w-40 sm:h-40">
            <img src={btnAndroidPng} alt="Android Button" className="w-full h-full object-contain" />
          </a>
        </div>
      </div>
      <div className="flex-grow">
        <NcImage className="rounded-[30rem]" src={rightImg} />
      </div>
    </div>)}
    </>
  );
};

export default SectionSubscribe2;