import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Nefes Kesici Bir Serüven",
    desc: "Dünyanın dört bir yanını keşfetmek için sınırları aşın. Yeni lezzetler, canlı festivaller, huzurlu plajlar ve daha fazlası için sadece bir adım atmanız yeterli.",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Sınırsız Keşiflerin Kapısını Aralayın",
    desc: "Yolculuğunuz, Todogo.com ile başlar. Konforlu araç kiralama ve sorunsuz transfer hizmetlerimizle keşfetmenin özgürlüğünü yaşayın.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Mavinin ve Yeşilin Buluşması",
    desc: "Todogo.com ile mavi suların serinliğinde, yeşilin her tonunu kucaklayan doğa içinde, bütçenize uygun, huzurlu bir tatil geçirin.",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      style={{ marginTop: '220px' }}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="Sizi düşlediğiniz tatil deneyimine götürmek için buradayız. Todogo.com ile unutulmaz anılar biriktirmeye hazır mısınız?">
      Hayalinizdeki Tatil Todogo.com ile Gerçek Olsun!
      </Heading>
      <div className="mt-10 mb-40 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center mt-auto">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
