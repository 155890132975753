import React from "react";
import imagePng from "images/logo-light.png";

const LogoSvgLight = () => {
  return (
    <img
      src={imagePng}
      alt="Logo"
      className="w-full hidden dark:block"
    />
  );
};

export default LogoSvgLight;