/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import DatesRangeInput from "../DatesRangeInput";
import LocationInput from "../LocationInput";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonSubmit from "../ButtonSubmit";
import ButtonClear from "../ButtonClear";

const CarsSearchForm = ({ onSubmit }: { onSubmit?: () => void }) => {
  const [fieldNameShow, setFieldNameShow] = useState<
    "" | "locationPickup" | "locationDropoff" | "dates"
  >("");
  const [locationInputPickUp, setLocationInputPickUp] = useState("");
  const [locationInputDropOff, setLocationInputDropOff] = useState("");
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [dropOffLocationType, setDropOffLocationType] = useState<
    "same" | "different"
  >("same");

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const today = new Date();
    setStartDate(today);
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setFieldNameShow("");
      }
    }

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">
              {locationInputPickUp ? "Nereden" : "Nereden gitmek istiyorsunuz?"}
            </span>
            <span>{locationInputPickUp || "Nereden"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Nereden gitmek istiyorsunuz?"
            defaultValue={locationInputPickUp}
            onChange={(value) => {
              setLocationInputPickUp(value);
            }}
            type="car"
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">
              {locationInputDropOff ? "Nereye" : "Nereye gitmek istiyorsunuz?"}
            </span>
            <span>{locationInputDropOff || "Nereye"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Nereye gitmek istiyorsunuz?"
            defaultValue={locationInputDropOff}
            onChange={(value) => {
              setLocationInputDropOff(value);
            }}
            type="car"
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">Gidiş Tarihiniz</span>
            <span>
              {startDate
                ? converSelectedDateToString([startDate, null])
                : "Tarih ekle"}
            </span>
          </button>
        ) : (
          <DatesRangeInput
            dateSelectCallback={(dates) => {
              if (dates && !Array.isArray(dates)) {
                setStartDate(dates);
              }
            }}
            dropOffLocationType={dropOffLocationType}
            isHotel={false}
            selectsRange={false}
            isTransfer={true}
          />
        )}
      </div>
    );
  };

  return (
    <div ref={containerRef}>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {renderInputLocationDropoff()}
        {renderInputDates()}
      </div>
      <div className="px-4 py-3 flex justify-between rounded-2xl mt-2">
        <ButtonClear className="mr-2" />
        <ButtonSubmit isDisabled />
      </div>
    </div>
  );
};

export default CarsSearchForm;
