import React, { FC, useState } from "react";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { MdLocationOn, MdEmail, MdPhone } from 'react-icons/md';
import rightImg from "images/about-hero-right.png";
import { MdOutlineLocationOn, MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';

export interface PageContactProps {
  className?: string;
}
const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const info = [
    {
      icon: <i className="las la-map-marked text-4xl"></i>,
      title: "Adres",
      desc: "Kirazlıdere Mahallesi, Eski Ankara Caddesi, No: 4/4, İç Kapı No: 5, Çekmeköy/İstanbul",
    },
    {
      icon: <i className="las la-phone text-4xl"></i>,
      title: "Telefon",
      desc: "+90 541 905 10 88"
    },
    {
      icon: <i className="las la-envelope text-4xl"></i>,
      title: "E-posta",
      desc: "marketing@todogo.com"
    }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Form gönderme işlemi simülasyonu
    await new Promise(resolve => setTimeout(resolve, 1000));
    setIsSubmitting(false);
    alert("Mesajınız başarıyla gönderildi!");
  };

  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      
      <Helmet>
        <title>TODOGO | İletişim</title>
      </Helmet>

      <div className="container mt-20 mb-20">
        <div className="flex justify-center">
          <img src={rightImg} alt="TODOGO" className="w-auto h-auto" />
        </div>
      </div>

      <div className="container mt-20 mb-20">
      <div className="grid md:grid-cols-3 gap-10">
        {info.map((item, index) => (
          <div 
            key={index}
            className="relative flex flex-col items-center p-8 rounded-3xl bg-white dark:bg-neutral-800 
              shadow-xl dark:shadow-2xl hover:shadow-2xl dark:hover:shadow-3xl 
              transform hover:-translate-y-1 transition-all duration-300"
          >
            <div className="mb-4 p-4 bg-neutral-50 dark:bg-neutral-800 rounded-full">
              {item.icon}
            </div>
            <h3 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
              {item.title}
            </h3>
            <span className="text-neutral-500 dark:text-neutral-400 text-center">
              {item.desc}
            </span>
          </div>
        ))}
      </div>
      </div>

      <div className="container mt-20 mb-20">
        <div className="w-full h-[400px] rounded-2xl overflow-hidden">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1505.6274671901387!2d29.204673084344785!3d41.01422405857891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cacf60c26db087%3A0xfdc02f580fdd840b!2sDijitalpark%20Teknokent!5e0!3m2!1str!2str!4v1710431567890!5m2!1str!2str"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

    </div>
  );
};

export default PageContact;
