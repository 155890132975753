import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";
export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.adults || 0
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue?.childrens || 0
  );
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(
    defaultValue?.infants || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.adults || 0);
  }, [defaultValue?.adults]);
  useEffect(() => {
    setGuestChildrenInputValue(defaultValue?.childrens || 0);
  }, [defaultValue?.childrens]);
  useEffect(() => {
    setGuestInfantsInputValue(defaultValue?.infants || 0);
  }, [defaultValue?.infants]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      adults: guestAdultsInputValue,
      children: guestChildrenInputValue,
      infants: guestInfantsInputValue,
    };
    if (type === "adults") {
      setGuestAdultsInputValue(value);
      newValue.adults = value;
    }
    if (type === "childrens") {
      setGuestChildrenInputValue(value);
      newValue.children = value;
    }
    if (type === "infants") {
      setGuestInfantsInputValue(value);
      newValue.infants = value;
    }
    onChange && onChange(newValue);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      <span className="mb-5 block font-semibold text-base sm:text-2xl">
        {`Yolcu Sayınız`}
      </span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "adults")}
        min={1}
        max={7}
        label="Yetişkin"
        desc="13 yaş ve üzeri"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, "childrens")}
        max={7}
        label="Çocuk"
        desc="2 ve 12 yaş arası"
      />

      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestInfantsInputValue}
        onChange={(value) => handleChangeData(value, "infants")}
        max={7}
        label="Bebek"
        desc="0 ve 2 yaş arası"
      />
    </div>
  );
};

export default GuestsInput;
