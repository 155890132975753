import React from 'react';
import { Helmet } from 'react-helmet-async';

const IptalIadeKosullari: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>İptal ve İade Koşulları | TODOGO</title>
      </Helmet>
      
      <div className="container mx-auto px-4 py-16 lg:py-24">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-center">İptal ve İade Koşulları</h1>
          
          <div className="space-y-6 text-neutral-600 dark:text-neutral-400">
            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                Todogo.com İptal ve İade Koşulları
              </h2>
              <p className="mb-4">
                Todogo.com üzerinden satın almış olduğunuz uçak biletlerinin iptal ve iade işlemleri, ilgili havayolu şirketinin belirlemiş olduğu kurallar çerçevesinde gerçekleştirilir. Bu kurallar havayolu şirketine, bilet sınıfına ve rezervasyon koşullarına bağlı olarak değişiklik gösterebilir. İşlem öncesinde, havayolu şirketinin iptal ve iade politikalarını kontrol etmenizi öneririz.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                Hizmet Bedeli ve İade Süreci
              </h2>
              <p className="mb-4">
                Uçak bileti iadesi talep edildiğinde, biletleme sırasında ödediğiniz hizmet bedeli iade edilmez. Ancak, iptal ve iade işlemleri için ek bir hizmet bedeli alınmamaktadır.
              </p>
              <p className="mb-4">
                İadesi mümkün olan veya ilgili havayolu şirketi tarafından iadesine onay verilen biletlerde, bilet bedeli, satın alma işlemi sırasında kullanılan ödeme yöntemine uygun olarak iade edilir.
              </p>
              <p className="mb-4">
                İadelerin banka veya kredi kartınıza yansıma süresi, bankaların işlem sürelerine ve kart programlarına bağlı olarak değişiklik gösterebilir. İade sürecinizle ilgili detaylı bilgi için bankanızla iletişime geçebilirsiniz.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                Bilgilendirme ve Onay Süreci
              </h2>
              <p className="mb-4">
                İptal ve iade talebiniz işleme alındığında, işlem durumu ile ilgili size e-posta yoluyla bilgilendirme yapılır. Bu e-postada, işleminizin detayları ve iade sürecine dair önemli bilgiler yer alacaktır.
              </p>
              <p className="mb-4">
                İptal ve iade işlemlerinin, rezervasyonda yer alan tüm yolcular için geçerli olduğunu unutmayınız. Kısmi iptal veya iade işlemleri yapılamamaktadır.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                Önemli Notlar
              </h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>İptal ve iade işlemleri, yalnızca satın alma işlemi Todogo.com üzerinden yapılmış biletler için geçerlidir.</li>
                <li>Üçüncü taraf kanallardan alınan biletlerde ilgili kanalın iptal ve iade politikaları geçerlidir.</li>
                <li>Bazı özel bilet sınıfları veya promosyonlu biletlerde iptal ve iade mümkün olmayabilir.</li>
                <li>Seyahatinizle ilgili iptal ve iade talepleriniz için müşteri hizmetlerimizden destek alabilirsiniz.</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default IptalIadeKosullari;