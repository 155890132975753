import { MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, FC } from "react";
import axios from "axios";

interface Props {
  onClick?: () => void;
  onChange?: (value: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  placeHolder?: string;
  desc?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  value?: any;
  hasError?: boolean;
  type: 'hotel' | 'flight' | 'car' | 'experiences';
}

interface Airport {
  Display: string;
  Code: string;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "",
  headingText = "Nereye gitmek istiyorsunuz?",
  placeHolder = "Nereye",
  desc = "Nereye gitmek istiyorsunuz?",
  type = "flight",
  value: propsValue = "",
  hasError = false,
}) => {
  const [value, setValue] = useState<string | Airport>(defaultValue);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [delay, setDelay] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [valueList, setValueList] = useState([]);

  const handleSelectLocation = (item: any) => {
    setValue(item);
    if (onChange) {
      onChange(item);
    }
  };

  const searchAirport = async (airport:string) => {
    if (airport.length >= 3){
      var response = await axios.get("/api/airport/search/"+airport);
      setValueList(response.data);
    }
  }

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: any;
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item:any) => (
            <div
              className="py-2 mb-1 flex items-center space-x-3 text-sm cursor-pointer"
              onClick={() => handleSelectLocation(item)}
              key={item.Code}
            >
              <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
              <span>{typeof item === 'string' ? item : item.Display}</span>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className={className} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-base sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
  <span className="absolute left-2.5 top-1/2 -translate-y-1/2">
    <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
  </span>
  <input
    className={`block w-full bg-transparent border-1 px-10 py-3 pr-12 border-neutral-500 dark:border-neutral-500 rounded-xl focus:ring-0 focus:outline-none focus:border-neutral-500 dark:focus:border-neutral-500 text-sm font-medium leading-none placeholder-black dark:placeholder-neutral-400 truncate placeholder:truncate text-black`}
    value={typeof value === 'string' ? value : value.Display}
    onChange={(e) => {
      const newValue = e.currentTarget.value;
      setValue(newValue);

      if (onChange) {
        onChange(newValue);
      }
      // Eğer 3 karakterden fazla değilse istek yapılmaz
      if (newValue.length >= 3) {
        // Önceki timeout'u iptal ediyoruz
        if (delay) clearTimeout(delay);

        // 300ms gecikme ile yeni timeout başlatıyoruz
        setDelay(
          setTimeout(async () => {
            await searchAirport(newValue);
          }, 300)
        );
      }else{
        setValueList([]);
      }
    }}
  />
</div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: "Popüler Aramalar",
                items: valueList.length > 0 ? valueList : type === 'flight' ? [
                  {Display:"İstanbul, Türkiye (Tüm Havalimanları)", Code:"ISTA"},
                  {Display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", Code:"SAW"},
                  {Display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", Code:"IST"},
                  {Display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", Code:"ESB"},
                  {Display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", Code:"ADB"}
                ] : [
                  "İstanbul, Türkiye",
                  "Ankara, Türkiye",
                  "Antalya, Türkiye",
                  "Bursa, Türkiye",
                  "Eskişehir, Türkiye"
                ],
              })
            : renderSearchValues({
                heading: "Popüler Aramalar",
                items: valueList.length > 0 ? valueList : type === 'flight' ? [
                  {Display:"İstanbul, Türkiye (Tüm Havalimanları)", Code:"ISTA"},
                  {Display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", Code:"SAW"},
                  {Display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", Code:"IST"},
                  {Display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", Code:"ESB"},
                  {Display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", Code:"ADB"}
                ] : [
                  "İstanbul, Türkiye",
                  "Ankara, Türkiye",
                  "Antalya, Türkiye",
                  "Bursa, Türkiye",
                  "Eskişehir, Türkiye"
                ],
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
