import React, { useState, useRef, useEffect, FC } from "react";
import { MapPinIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "./ClearDataButton";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import axios from "axios";

export interface LocationInputProps {
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  value?: string;
  onChange?: (value: string) => void;
  hasError?: boolean;
  type: 'hotel' | 'flight' | 'car' | 'experiences';
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  onInputDone,
  placeHolder = "Nereye",
  desc = "Nereye gitmek istiyorsunuz?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  value: propsValue = "",
  onChange,
  hasError = false,
  type,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [delay, setDelay] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [valueList, setValueList] = useState([]);

  useEffect(() => {
    setShowPopover(autoFocus);
    if (autoFocus && !!inputRef.current) {
      setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      }, 200);
    }
  }, [autoFocus]);

  useEffect(() => {
    setValue(propsValue);
  }, [propsValue]);

  useOutsideAlerter(containerRef, () => {
    setShowPopover(false);
  });

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleSelectLocation = (item: string) => {
    setValue(item);
    setShowPopover(false);
    if (onChange) {
      onChange(item);
    }
  };
  const searchAirport = async (airport:string) => {
    if (airport.length >= 3){
      var response = await axios.get("/api/airport/search/"+airport);
      setValueList(response.data);
    }
  }
  const renderRecentSearches = () => {
    const searches = type === 'hotel'
      ? [
        "İstanbul, Türkiye",
        "Ankara, Türkiye",
        "Antalya, Türkiye",
        "Bursa, Türkiye",
        "Eskişehir, Türkiye"
        ]
      : [
          {Display:"İstanbul, Türkiye (Tüm Havalimanları)", Code:"ISTA"},
          {Display:"İstanbul, Türkiye SAW (Sabiha Gökçen Havalimanı)", Code:"SAW"},
          {Display:"İstanbul, Türkiye IST (İstanbul Havalimanı)", Code:"IST"},
          {Display:"Ankara, Türkiye ESB (Esenboğa Havalimanı)", Code:"ESB"},
          {Display:"İzmir, Türkiye ADB (Adnan Menderes Havalimanı)", Code:"ADB"}
        ];

    return (
      <>
        <h3 className="block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base text-neutral-800 dark:text-neutral-100">
          Popüler Aramalar
        </h3>
        <div className="mt-2">
          {searches.map((item:any) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item.Code}
              className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className=" block text-neutral-700 dark:text-neutral-200">
                {item.Display}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    var searches = [];
    if (type === 'hotel'){
      searches = [
        "İstanbul, Türkiye",
        "Ankara, Türkiye",
        "Antalya, Türkiye",
        "Bursa, Türkiye",
        "Eskişehir, Türkiye"
      ];
    }else{
      searches = valueList;
    }

    return (
      <>
        {searches.map((item:any) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item.Code}
            className="flex px-4 sm:px-6 items-center space-x-3 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <MapPinIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block text-neutral-700 dark:text-neutral-200">
              {item.Display}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex flex-1`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative z-10 [ nc-hero-field-padding--small ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused--2" : ""
        }`}
      >
        <div className="flex-1">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-400 xl:text-base font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => {
              const newValue = e.currentTarget.value;
              console.log(newValue);
              setValue(newValue);

              if (onChange) {
                onChange(newValue);
              }
              // Eğer 3 karakterden fazla değilse istek yapılmaz
              if (newValue.length >= 3) {
                // Önceki timeout'u iptal ediyoruz
                if (delay) clearTimeout(delay);

                // 300ms gecikme ile yeni timeout başlatıyoruz
                setDelay(
                  setTimeout(async () => {
                    await searchAirport(newValue);
                  }, 300)
                );
              }
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className={hasError ? "text-red-400" : "text-neutral-400"}>
              {!!value ? placeHolder : desc}
            </span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                if (onChange) {
                  onChange("");
                }
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (!value || valueList.length > 0)&& (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[400px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-5 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : renderRecentSearches()}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
