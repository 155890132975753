/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, ReactNode } from "react";
import imagePng from "images/hero-right2.png";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage: "Otel" | "Experiences" | "Transfer" | "Uçak";
  currentTab: SearchTab;
  rightImage?: string;
  formFlightData?: any;
  formStayData?: any;
  arrivalLocation?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = imagePng,
  formFlightData,
  formStayData,
  arrivalLocation,
}) => {

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative ${className}`}
      data-nc-id="SectionHeroArchivePage"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-center justify-center space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mr-10 xl:mr-0">
        
          <div className="flex items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center"
          style={{
            marginTop: "150px",
          }}
          >
          </div>
        </div>
      </div>
      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm 
          currentPage={currentPage}
          currentTab={currentTab}
          formFlightData={formFlightData}
          formStayData={formStayData}
        />
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;
