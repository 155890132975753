import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useNavigate } from "react-router-dom";

export interface StaySearchFormProps {}

const StaySearchForm: FC<StaySearchFormProps> = () => {

  const navigate = useNavigate();

  const [stayLocation, setStayLocation] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [guests, setGuests] = useState([{ adults: 1, childrens: 0 }]);
  const [stayError, setStayError] = useState(false);

  const getTodayDate = () => {
    const today = new Date();
    return today.toLocaleDateString("tr-TR");
  };

  const handleStayChange = (value: string) => {
    setStayLocation(value);
    if (value === "") {
      setStayError(false);
    } else {
      setStayError(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let hasError = false;

    if (!stayLocation) {
      setStayError(true);
      hasError = true;
    } else {
      setStayError(false);
    }

    if (hasError) {
      return;
    }

    const todayDate = getTodayDate();

    const formStayData = {
      stayLocation,
      stayDates: {
        startDate: startDate
          ? startDate.toLocaleDateString("tr-TR")
          : todayDate,
        endDate: endDate ? endDate.toLocaleDateString("tr-TR") : todayDate,
      },
      stayGuests: {
        guests,
        adultGuests: guests.reduce((total, room) => total + room.adults, 0),
        childrenGuests: guests.reduce(
          (total, room) => total + room.childrens,
          0
        ),
        totalGuests: guests.reduce(
          (total, room) => total + room.adults + room.childrens,
          0
        ),
        totalRooms: guests.length,
      },
    };
    navigate("/listing-stay-map", { state: formStayData });
  };

  const renderForm = () => {
    return (
      <form
        className="relative flex rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800"
        onSubmit={handleSubmit}
      >
        <LocationInput
          type="hotel"
          className="flex-[1.5]"
          placeHolder="Nereye"
          desc={
            stayError
              ? "Lütfen bu alanı doldurun."
              : "Nereye gitmek istiyorsunuz?"
          }
          value={stayLocation}
          onChange={handleStayChange}
          hasError={stayError}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput
          className="flex-[1.2]"
          onChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-1"
          onGuestsChange={setGuests}
          onSubmit={handleSubmit}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
