import React, { FC, useState, useEffect } from "react";
import LocationInput from "../LocationInput";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "../type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { useNavigate } from "react-router-dom";

export interface FlightSearchFormProps {
  formFlightData?: any;
  onFlightDataChange?: (newData: any) => void;
}

const FlightSearchForm: FC<FlightSearchFormProps> = ({ formFlightData, onFlightDataChange }) => {

  const navigate = useNavigate();

  const [flightDirection, setFlightDirection] = useState("Tek Yön");

  const flightClasses = [
    {
      name: "Economy",
    },
    {
      name: "Business",
    },
  ];

  const [flightClass, setFlightClass] = useState("Economy");

  const [guestAdultsValue, setGuestAdultsValue] = useState(1);
  const [guestChildrensValue, setGuestChildrenValue] = useState(0);
  const [guestInfantsValue, setGuestInfantsValue] = useState(0);

  useEffect(() => {
    if (formFlightData) {
      setFlightDirection(formFlightData.flightDirection || "Tek Yön");
      setFlightClass(formFlightData.flightClass || "Economy");
      setGuestAdultsValue(formFlightData.flightGuests.adultGuests || 1);
      setGuestChildrenValue(formFlightData.flightGuests.childrenGuests || 0);
      setGuestInfantsValue(formFlightData.flightGuests.infantGuests || 0);
      setDepartureLocation(formFlightData.flightLocations.departureLocation || "");
      setDepartureLocationCode(formFlightData.flightLocations.departureLocationCode || "");
      setArrivalLocation(formFlightData.flightLocations.arrivalLocation || "");
      setArrivalLocationCode(formFlightData.flightLocations.arrivalLocationCode || "");

      // Tarih güncellemeleri
      if (formFlightData.flightDates) {
        if (formFlightData.flightDates.departureDate) {
          setDepartureDate(formFlightData.flightDates.departureDate);
        }
        if (formFlightData.flightDates.returnDate) {
          setReturnDate(formFlightData.flightDates.returnDate);
        } else if (formFlightData.flightDirection === "Gidiş ve Dönüş") {
          setReturnDate(departureDate);
        } else {
          setReturnDate(null);
        }
      }
    }
  }, [formFlightData]);

  const handleChangeGuestData = (value: number, type: keyof GuestsObject) => {
    if (type === "adults") {
      setGuestAdultsValue(value);
    }
    if (type === "childrens") {
      setGuestChildrenValue(value);
    }
    if (type === "infants") {
      setGuestInfantsValue(value);
    }
  };

  const totalGuests = guestAdultsValue + guestChildrensValue + guestInfantsValue;

  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [departureLocationCode, setDepartureLocationCode] = useState("");
  const [arrivalLocationCode, setArrivalLocationCode] = useState("");

  const handleDepartureChange = (value: any) => {
    setDepartureLocation(value.Display);
    setDepartureLocationCode(value.Code);
    setDepartureError(false);
  };

  const handleArrivalChange = (value: any) => {
    setArrivalLocation(value.Display);
    setArrivalLocationCode(value.Code);
    setArrivalError(false);
  };

  const [departureDate, setDepartureDate] = useState<Date>(new Date());
  const [returnDate, setReturnDate] = useState<Date | null>(flightDirection !== "Tek Yön" ? new Date() : null);

  const [departureError, setDepartureError] = useState(false);
  const [arrivalError, setArrivalError] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    let hasError = false;

    if (!departureLocation) {
      setDepartureError(true);
      hasError = true;
    } else {
      setDepartureError(false);
    }

    if (!arrivalLocation) {
      setArrivalError(true);
      hasError = true;
    } else {
      setArrivalError(false);
    }

    if (hasError) {
      return;
    }

    const formFlightData = {
      flightDirection,
      flightClass,
      flightGuests: {
        adultGuests: guestAdultsValue,
        childrenGuests: guestChildrensValue,
        infantGuests: guestInfantsValue,
        totalGuests: totalGuests,
      },
      flightLocations: {
        departureLocation: departureLocation,
        departureLocationCode: departureLocationCode,
        arrivalLocation: arrivalLocation,
        arrivalLocationCode: arrivalLocationCode,
      },
      flightDates: {
        departureDate: departureDate,
        returnDate:
          flightDirection === "Tek Yön"
            ? undefined
            : returnDate,
      },
    };
    navigate("/listing-flights", { state: formFlightData });
  };

  const handleFlightDirectionChange = (direction: string) => {
    setFlightDirection(direction);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    let newDepartureDate = today;
    let newReturnDate = direction === "Gidiş ve Dönüş" ? today : null;

    setDepartureDate(newDepartureDate);
    setReturnDate(newReturnDate);
    if (onFlightDataChange) {
      onFlightDataChange({
        flightDirection: direction,
        flightDates: {
          departureDate: newDepartureDate,
          returnDate: newReturnDate,
        },
      });
    }
  };

  const renderGuest = () => {
    return (
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as="button"
              className={`
            ${open ? "" : ""}
            px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{`${totalGuests || ""} Yolcu`}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdultsValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "adults")
                  }
                  max={7}
                  min={1}
                  label="Yetişkin"
                  desc="13 yaş ve üzeri"
                />
                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestChildrensValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "childrens")
                  }
                  max={7}
                  label="Çocuk"
                  desc="2 ve 12 yaş arası"
                />

                <NcInputNumber
                  className="w-full mt-6"
                  defaultValue={guestInfantsValue}
                  onChange={(value) =>
                    handleChangeGuestData(value, "infants")
                  }
                  max={7}
                  label="Bebek"
                  desc="0 ve 2 yaş arası"
                />
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderSelectClass = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none text-xs`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <span>{flightClass}</span>
              <ChevronDownIcon
                className={`${
                  open ? "" : "text-opacity-70"
                } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 w-screen max-w-[200px] sm:max-w-[220px] px-4 top-full mt-3 transform -translate-x-1/2 left-1/2 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    {flightClasses.map((item) => (
                      <button
                        key={item.name}
                        onClick={(e) => {
                          e.preventDefault();
                          setFlightClass(item.name);
                          close();
                        }}
                        className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium">{item.name}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex flex-row flex-wrap border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            flightDirection === "Tek Yön"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => handleFlightDirectionChange("Tek Yön")}
        >
          Tek Yön
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-3 ${
            flightDirection === "Gidiş ve Dönüş"
              ? "bg-black shadow-black/10 shadow-lg text-white"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={() => handleFlightDirectionChange("Gidiş ve Dönüş")}
        >
          Gidiş ve Dönüş
        </div>

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 mr-2 my-1 sm:mr-3"></div>

        <div className="mr-2 my-1 sm:mr-3 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="my-1 border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderGuest()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form
        className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
        onSubmit={handleSubmit}
      >
        {renderRadioBtn()}
        <div className="flex flex-1 rounded-full">
          <LocationInput
            type="flight"
            placeHolder="Nereden"
            desc={
              departureError
                ? "Lütfen bu alanı doldurun."
                : "Nereden gitmek istiyorsunuz?"
            }
            className="flex-1"
            value={departureLocation}
            onChange={handleDepartureChange}
            hasError={departureError}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            type="flight"
            placeHolder="Nereye"
            desc={
              arrivalError
                ? "Lütfen bu alanı doldurun."
                : "Nereye gitmek istiyorsunuz?"
            }
            className="flex-1"
            divHideVerticalLineClass="-inset-x-0.5"
            value={arrivalLocation}
            onChange={handleArrivalChange}
            hasError={arrivalError}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={flightDirection !== "Tek Yön"}
            className="flex-1"
            startDate={departureDate}
            endDate={returnDate}
            onChange={({ startDate, endDate }) => {
              console.log(startDate, endDate);
              setDepartureDate(startDate);
              if (flightDirection !== "Tek Yön") {
                setReturnDate(endDate);
              } else {
                setReturnDate(null);
              }
            }}
            onSubmit={handleSubmit}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;