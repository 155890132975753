import React, { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  const renderForm = () => {
    return (
      <form className="w-full relative ">
        <div className="flex flex-row w-full rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
          <LocationInput
            type="car"
            placeHolder="Nereden"
            desc="Nereden gitmek istiyorsunuz?"
            className="flex-1"
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            type="car"
            placeHolder="Nereye"
            desc="Nereye gitmek istiyorsunuz?"
            className="flex-1"
            divHideVerticalLineClass="-inset-x-0.5"
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <RentalCarDatesRangeInput className="flex-1" />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default RentalCarSearchForm;
