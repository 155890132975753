import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';

export interface SocialsList1Props {
  className?: string;
}

const socials: SocialType[] = [
  { name: "", icon: "bi bi-facebook", href: "https://www.facebook.com/todogocom" },
  { name: "", icon: "bi bi-instagram", href: "https://www.instagram.com/todogocom/" },
  { name: "", icon: "bi bi-twitter-x", href: "https://x.com/Todogocom" },
  { name: "", icon: "bi bi-youtube", href: "https://www.youtube.com/@Todogocom" },
  { name: "", icon: "bi bi-tiktok", href: "https://www.tiktok.com/@todogocom" },
  { name: "", icon: "bi bi-linkedin", href: "https://www.linkedin.com/company/todogocom" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className="flex items-center text-2xl text-neutral-500 dark:text-neutral-400 text-center hover:text-[#333333] dark:hover:text-[white] leading-none space-x-2 group"
        key={index}
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className={item.icon}></i>
        <span className="hidden lg:block text-sm">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
