import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";

export interface GuestsObject {
  adults: number;
  children: number;
}

export interface GuestsInputProps {
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
  onSubmit?: (event: React.FormEvent) => void;
  onGuestsChange: (guests: GuestsObject[]) => void;
  guests: GuestsObject[];
}

const GuestsInput: FC<GuestsInputProps> = ({
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  onSubmit,
  onGuestsChange,
  guests,
}) => {
  const handleChangeData = (
    roomIndex: number,
    value: number,
    type: keyof GuestsObject
  ) => {
    const newGuests = [...guests];
    newGuests[roomIndex] = {
      ...newGuests[roomIndex],
      [type]: value,
    };
    onGuestsChange(newGuests);
  };

  const handleAddRoom = () => {
    if (guests.length < 7) {
      onGuestsChange([...guests, { adults: 1, children: 0 }]);
    }
  };

  const handleRemoveRoom = (roomIndex: number) => {
    if (guests.length > 1) {
      onGuestsChange(guests.filter((_, index) => index !== roomIndex));
    }
  };

  const totalGuests = guests.reduce(
    (total, room) => total + room.adults + room.children,
    0
  );

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 z-10 flex items-center focus:outline-none ${
              open ? "nc-hero-field-focused" : ""
            }`}
          >
            <Popover.Button
              className={`relative z-10 flex-1 flex text-left items-center ${fieldClassName} space-x-3 focus:outline-none`}
              onClickCapture={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <UserPlusIcon className="w-5 h-5 lg:w-7 lg:h-7" />
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {guests.length} Oda, {totalGuests} Misafir
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  Misafirler
                </span>
              </div>

              {!!totalGuests && open && (
                <ClearDataButton
                  onClick={() => {
                    onGuestsChange([{ adults: 1, children: 0 }]);
                  }}
                />
              )}
            </Popover.Button>
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit
                  href="/listing-flights"
                  onClick={onSubmit}
                  isDisabled={true}
                />
              </div>
            )}
          </div>

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-1 bg-white dark:bg-neutral-800"></div>
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
              {guests.map((room, index) => (
                <div key={index} className="space-y-4">
                  {index > 0 && <div className="mt-4" />}
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">{index + 1}. Oda</h3>
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveRoom(index)}
                        className="text-xs text-neutral-500 dark:text-neutral-400 font-normal"
                      >
                        Oda Kaldır
                      </button>
                    )}
                  </div>

                  <NcInputNumber
                    className="w-full"
                    defaultValue={room.adults}
                    onChange={(value) =>
                      handleChangeData(index, value, "adults")
                    }
                    max={7}
                    min={1}
                    label="Yetişkin"
                    desc="17 yaş ve üzeri"
                  />
                  <NcInputNumber
                    className="w-full mt-6"
                    defaultValue={room.children}
                    onChange={(value) =>
                      handleChangeData(index, value, "children")
                    }
                    max={7}
                    label="Çocuk"
                    desc="0 ve 17 yaş arası"
                  />
                  {index < guests.length - 1 && (
                    <div className="border-t border-neutral-400 dark:border-neutral-500" />
                  )}
                </div>
              ))}
              {guests.length < 7 && (
                <button
                  type="button"
                  onClick={handleAddRoom}
                  className="mt-4 text-xs text-neutral-500 dark:text-neutral-400 font-normal"
                >
                  Oda Ekle
                </button>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;
