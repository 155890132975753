/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, useState, useEffect, useCallback } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { useNavigate } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardHProps {
  className?: string;
  data: StayDataType;
  formStayData?: any;
  onSelect: (data: StayCardHProps['data'] & { selectedPackage: string | null }) => void;
  isSelected?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  data = DEMO_DATA,
  formStayData,
  onSelect,
  isSelected = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<string | null>("Family");
  const [currentPrice, setCurrentPrice] = useState<number>(Number(data.price));
  const [initialPrice, setInitialPrice] = useState<number>(Number(data.price));
  const [isPackageSelectionShown, setIsPackageSelectionShown] = useState(false);

  const navigate = useNavigate();

  const handleCheckoutClick = () => {
    navigate("/stay-checkout", { 
      state: { 
        stayData: {
          ...data,
          userCriteria: {
            stayDates: formStayData?.stayDates,
            stayGuests: formStayData?.stayGuests,
            stayLocation: formStayData?.stayLocation
          },
          selectedPackage
        }
      } 
    });
  };

  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    stayPackage,
  } = data;

  const updatePrices = useCallback(() => {
    if (data.stayPackage?.Family?.price) {
      const familyPrice = Number(data.stayPackage.Family.price);
      setInitialPrice(familyPrice);
      setCurrentPrice(familyPrice);
    } else {
      setInitialPrice(Number(data.price));
      setCurrentPrice(Number(data.price));
    }
  }, [data]);

  useEffect(() => {
    updatePrices();
  }, [updatePrices]);

  useEffect(() => {
    if (data.stayPackage?.Family) {
      setSelectedPackage("Family");
    }
  }, [data]);

  const handleCardClick = (e: React.MouseEvent) => {
    if (isSelected) return;
    if ((e.target as HTMLElement).closest('.package-selection-area')) {
      return;
    }

    setIsOpen(!isOpen);

    if (!isOpen && data.stayPackage) {
      setIsPackageSelectionShown(true);
    }

    if (isOpen) {
      setIsPackageSelectionShown(false);
    }
  };

  const handleSelectStay = useCallback(() => {
    if (!data.stayPackage) {
      onSelect({ ...data, selectedPackage: null });
    } else if (isPackageSelectionShown) {
      const selectedPrice = selectedPackage && data.stayPackage[selectedPackage as keyof typeof data.stayPackage]?.price
        ? Number(data.stayPackage[selectedPackage as keyof typeof data.stayPackage].price)
        : Number(data.price);
      onSelect({ ...data, price: selectedPrice.toString(), selectedPackage });
      setIsPackageSelectionShown(false);
    } else {
      setIsOpen(true);
      setIsPackageSelectionShown(true);
    }
  }, [data, isPackageSelectionShown, selectedPackage, onSelect]);

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`StayCardH_${id}`}
          href={href}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderTienIch = () => {
    return (
      <div className="hidden sm:grid grid-cols-3 gap-2">
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-user text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 guests
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-bed text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 beds
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-bath text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              3 baths
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-smoking-ban text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              No smoking
            </span>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex items-center space-x-3">
            <i className="las la-door-open text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              6 bedrooms
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className="las la-wifi text-lg"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              Wifi
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              {listingCategory.name} in {address}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {renderTienIch()}
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <StartRating reviewCount={reviewCount} point={reviewStart} />
          
          <span className="text-base font-semibold text-secondary-500"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCheckoutClick();
                          }}
                        
          >
            {price}
            {` `}
            
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /night
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderPackageDetails = (packageName: string, packageData: any) => {
    const packagePrice = packageData.price || data.price;
    
    return (
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          <h3 className="font-medium text-lg">{packageName}</h3>
          <ul className="text-sm">
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-suitcase me-2"></i> 
              {packageName}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-briefcase me-2"></i> 
              {packageName}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-utensils me-2"></i> 
              {packageName}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-exchange-alt me-2"></i> 
              {packageName}
            </li>
            <li className="text-sm text-neutral-500 font-normal mt-4">
              <i className="las la-times me-2"></i> 
              {packageName}
            </li>
            <li className="mt-4">
              <span className="text-xl font-semibold text-secondary-6000">
                {formatPrice(packagePrice)[0]}
                <span className="text-sm">,{formatPrice(packagePrice)[1]}</span> TL
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderPackages = () => {
    if (!data.stayPackage) return null;

    return (
      <div className="package-selection-area mt-6 border-t border-neutral-100 dark:border-neutral-800 pt-6">
        <div className="grid grid-cols-3 gap-4">
          {Object.entries(data.stayPackage).map(([packageName, packageData]) => (
            <div
              key={packageName}
              className={`p-4 rounded-3xl border cursor-pointer ${
                selectedPackage === packageName
                  ? "border-neutral-100 bg-neutral-50 dark:bg-black/20 dark:border-neutral-800"
                  : "border-neutral-100 dark:bg-neutral-900 dark:border-neutral-800"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handlePackageSelect(packageName);
              }}
            >
              {renderPackageDetails(packageName, packageData)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handlePackageSelect = (packageName: string) => {
    setSelectedPackage(packageName);
    if (data.stayPackage && packageName in data.stayPackage) {
      const newPrice = Number(data.stayPackage[packageName as keyof typeof data.stayPackage].price);
      setCurrentPrice(newPrice);
    }
  };

  const displayPrice = selectedPackage && data.stayPackage && selectedPackage in data.stayPackage
    ? Number(data.stayPackage[selectedPackage as keyof typeof data.stayPackage].price)
    : Number(data.price);

  const formatPrice = (price: number): [string, string] => {
    const [integerPart, decimalPart] = price.toFixed(2).split('.');
    return [
      integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
      decimalPart
    ];
  };

  return (
    <div
      className={`nc-StayCardH group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 rounded-2xl ${className}`}
      data-nc-id="StayCardH"
      onClick={handleCardClick}
    >
      <div className="grid grid-cols-1 md:flex md:flex-row">
        {renderSliderGallery()}
        <div className="flex-grow flex flex-col">
          {renderContent()}
        </div>
      </div>
      {isOpen && data.stayPackage && renderPackages()}
    </div>
  );
};

export default StayCardH;
