import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Heading2 from "components/Heading/Heading2";
import { CheckCircleIcon, HomeIcon } from '@heroicons/react/24/solid';
import { motion, Variants } from 'framer-motion';

export interface PayPageProps {
  className?: string;
}

interface FlightCardProps {
  segments: Array<any>;
  type: string;
}

const FlightCard: FC<FlightCardProps> = ({ segments, type }) => {
  return (
    <>
      <h3 className="text-1xl font-semibold">{type} Uçuşu</h3>
      <div className="nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden">
        {segments.map((segment: any, index: number) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div className="my-4 border-t border-neutral-200 dark:border-neutral-700 pt-4"></div>
            )}
            <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
              <div className="w-24 flex-shrink-0">
                <img src={segment.airlineLogo} className="w-10" alt="" />
              </div>
              <div className="flex-grow grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <div className="font-medium">{segment.MarkettingAirlineName}</div>
                  <div className="text-sm text-neutral-500">{segments.length == 1 ? "Direkt Uçuş" : "Aktarmalı Uçuş"}</div>
                </div>
                <div>
                  <div className="font-medium">{segment.DepartureAirportCode} - {segment.ArrivalAirportCode}</div>
                  <div className="text-sm text-neutral-500">{segment.FlightNumber}</div>
                </div>
                <div>
                  <div className="font-medium">{new Date(segment.DepartureDateTime).toLocaleString()} - {new Date(segment.ArrivalDateTime).toLocaleString()}</div>
                  <div className="text-sm text-neutral-500">{Math.floor(segment.JourneyDurationInMinute / 60)} saat {segment.JourneyDurationInMinute % 60} dakika</div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ticketingCompleted, setTicketingCompleted] = useState<boolean | null>(null);
  const [billingCompleted, setBillingCompleted] = useState<boolean | null>(null);
  const [shoppingCart, setShoppingCart] = useState<any | null>(null);
  const [finalizeError, setFinalizeError] = useState<string | null>(null);
  const [billingError, setBillingError] = useState<string | null>(null);

  const query = new URLSearchParams(location.search);
  const shoppingCartId = query.get('ShoppingCartId');
  const ok = query.get('Ok') === 'True';
  const approved = query.get('Approved') === 'True';
  const error = query.get('ErrorMessage');

  useEffect(() => {
    if (!ok || !approved) {
      // Ödeme başarısız olduğunda, checkout sayfasına yönlendir
      navigate(`/checkout?ShoppingCartId=${shoppingCartId}&error=${encodeURIComponent(error || 'Ödeme işlemi başarısız oldu.')}`);
    } else {
      // Başarılı ödeme sonrası mevcut işlemleri yap
      axios.get(`/api/flight/complete?ShoppingCartId=${shoppingCartId}`)
        .then(response => {
          if (!response.data || !response.data.ShoppingCart) {
            setErrorMessage("Bilet bilgileri alınamadı. Lütfen bizimle iletişime geçiniz.");
            console.error("Beklenmeyen bir hata oluştu:", response.data);
            setTicketingCompleted(false);
            return;
          }
          if (response.data.HasError) {
            setErrorMessage(response.data.ErrorMessage || "Bilet bilgileri alınamadı. Lütfen bizimle iletişime geçiniz.");
            console.error("Bilet bilgileri alınamadı:", response.data.ErrorMessage);
            setTicketingCompleted(false);
            return;
          }else{
            setBillingCompleted(response.data.IsBilled);
            setTicketingCompleted(response.data.IsFinalized);
            setShoppingCart(response.data.ShoppingCart);
            setBillingError(response.data.BillingErrorMessage);
            setFinalizeError(response.data.FinalizeErrorMessage);

            if (response.data.IsFinalized){
              setShoppingCart(response.data.ShoppingCart);
            }
          }
        })
        .catch(error => {
          console.error("Error completing the flight:", error);
          setTicketingCompleted(false);
        });
    }
  }, [ok, approved, shoppingCartId, error]);

  const getLocationWithoutParentheses = (location: string | undefined) => {
    if (!location) return "";
    return location.replace(/\([^)]*\)/g, "").trim();
  };

  const checkIconVariants: Variants = {
    hidden: { 
      opacity: 0, 
      scale: 0.5,
      y: -50, // Yukarıdan başlaması için negatif y değeri
      rotate: 0
    },
    visible: { 
      opacity: 1, 
      scale: 1,
      y: 0, // Normal pozisyonuna gelme
      rotate: 360,
      transition: {
        type: "spring",
        stiffness: 100,
        delay: 0.5,
        damping: 15,
        duration: 1.5
      }
    }
  };

  const homeIconVariants: Variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 1,
        delay: 1.5, 
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  const hoverRotate: Variants = {
    hover: {
      scale: 1.1,
      rotate: 360,
      transition: {
        duration: 2,
        type: "spring",
        stiffness: 50,
        damping: 10,
      }
    }
  };

  const renderContent = () => {
    if (ticketingCompleted === null) {
      return <div className="text-center">Yükleniyor...</div>;
    }

    if (ticketingCompleted === false) {
      return (
        <div className="text-center">
          <h2 className="mt-4 text-3xl font-bold text-red-600">
            {errorMessage}
            {finalizeError && <p className="mt-4 text-lg text-red-600">Biletleme hatası : {finalizeError}</p>}
            {/* {billingError && <p className="mt-4 text-lg text-red-600">Biletleme başarılı oldu ancak faturalandırmada hata oluştu : {billingError}</p>} */}
          </h2>
          <button
            onClick={() => navigate('/payment?ShoppingCartId=' + shoppingCartId)}
            className="mt-8 px-4 py-2 bg-blue-600 text-white rounded"
          >
            Tekrar Dene
          </button>
        </div>
      );
    }

    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8"
      >
        <div className="text-center">
          <motion.div
            initial="hidden"
            animate="visible"
            variants={checkIconVariants}
            className="inline-block"
          >
            <CheckCircleIcon className="h-16 w-16 text-secondary-6000" />
          </motion.div>
          <h2 className="mt-4 text-3xl font-bold text-secondary-6000">
            Tebrikler! Ödemeniz Onaylandı.
          </h2>
          <div className="mt-4 text-center font-medium text-xl text-neutral-500">
            <p>Bilet bilgileriniz ve uçuş detaylarınız, ücretsiz SMS ve e-posta ile tarafınıza iletilecektir.</p>
            <p className="mt-4">Lütfen aşağıda yer alan "Uçuş Bilgileri" ve "Bilet Bilgileri" bölümlerini kontrol ediniz ve bir problem olduğunu düşünüyorsanız, lütfen bizimle iletişime geçiniz.</p>
            <p className="mt-4">TODOGO.com'u tercih ettiğiniz için teşekkür eder, iyi yolculuklar dileriz.</p>
          </div>
          
          <motion.div 
            className="flex justify-center mt-8"
            initial="hidden"
            animate="visible"
            variants={homeIconVariants}
          >
            <motion.div
              whileHover="hover"
              whileTap={{ scale: 0.9 }}
              variants={hoverRotate}
              onClick={() => navigate('/')}
              className="cursor-pointer inline-block"
            >
              <HomeIcon className="w-16 h-16 text-secondary-6000" />
            </motion.div>
          </motion.div>
        </div>

        <div className="border-t border-neutral-200 dark:border-neutral-700 pt-8">
        </div>

        <h2 className="text-2xl font-semibold">Uçuş Bilgileri</h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          {shoppingCart && (
            <>
              <div className="-mb-6">
                <Heading2
                  heading={
                    <div className="flex justify-center text-xl font-medium">
                      {`${getLocationWithoutParentheses(
                        shoppingCart.AirBookings[0].FlightSegments[0].DepartureAirportName
                      )} - ${getLocationWithoutParentheses(
                        shoppingCart.AirBookings[0].FlightSegments[shoppingCart.AirBookings[0].FlightSegments.length - 1].ArrivalAirportName
                      )}`}
                    </div>
                  }
                  subHeading={
                    <span className="block items-center text-base text-md text-neutral-500 text-center mt-4">
                      <span>
                        {new Date(shoppingCart.AirBookings[0].FlightSegments[0].DepartureDateTime).toLocaleString()}
                        {shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 1).length > 0 && 
                          ` - ${new Date(shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 1)[0].DepartureDateTime).toLocaleString()}`}
                      </span>
                      <span className="mx-2">·</span>
                      {shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 1).length > 0 ? "Gidiş - Dönüş" : "Tek Yön"}
                      {/* <span className="mx-2">·</span>
                      {flightData.selectedDepartFlight.flightClass} */}
                      <span className="mx-2">·</span>
                      {shoppingCart.Passengers.length} Yolcu
                    </span>
                  }
                />
              </div>
              <FlightCard segments={shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 0)} type="Gidiş" />
            </>
          )}
          {shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 1).length > 0 && (
            <>
              <FlightCard segments={shoppingCart.AirBookings[0].FlightSegments.filter((x:any) => x.DirectionInd == 1)} type="Dönüş" />
            </>
          )}
        </div>

        {renderPassengerInfo()}
      </motion.div>
    );
  };

  const renderPassengerInfo = () => {
    if (!shoppingCart) {
      console.error("Gerekli veriler eksik");
      return null;
    }
  
    const renderFlightInfo = (direction: string) => {
      if (!shoppingCart) {
        console.error(`${direction} uçuş bilgileri eksik:`, shoppingCart);
        return null;
      }
  
      return (
        <>
          <h3 className="text-1xl font-semibold mt-6">{direction} Uçuşu</h3>
          {shoppingCart.Passengers.map((passenger: any, passengerIndex: number) => (
            <div key={passengerIndex} className="nc-FlightCard group p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden mt-6">
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                <div className="flex-grow grid grid-cols-1 sm:grid-cols-5 gap-4 text-center">
                  <div>
                    <div className="font-medium">Ad</div>
                    <div className="text-sm text-neutral-500">{passenger.FirstName || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="font-medium">Soyad</div>
                    <div className="text-sm text-neutral-500">{passenger.LastName || 'N/A'}</div>
                  </div>
                  <div>
                    <div className="font-medium">Bilet Numarası</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.AirBookings[0].PassengerBreakdowns[passengerIndex].TicketNumber || 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium">PNR</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.AirBookings[0].BookingCode}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium">Bagaj Hakkı</div>
                    <div className="text-sm text-neutral-500">
                      {shoppingCart.AirBookings[0].PassengerBreakdowns[passengerIndex].BaggageAllowance || 'Belirtilmemiş'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    };
  
    return (
      <div>
        <h3 className="text-2xl font-semibold">Bilet Bilgileri</h3>
        <div className="border-b border-neutral-200 dark:border-neutral-700 my-9"></div>
        {renderFlightInfo("Yolcu Bilgileri")}
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-24 mb-24 flex flex-col-reverse lg:flex-row">
        <div className="w-full">
          {renderContent()}
        </div>
      </main>
    </div>
  );
};

export default PayPage;
