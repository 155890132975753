import { FC } from "react";
import FAQCard, { FAQCardProps } from "components/FrequentlyAskedQuestionsCard/FrequentlyAskedQuestionsCard";

export interface SectionGridFilterCardProps {
  className?: string;
}

const DEMO_DATA: FAQCardProps["data"][] = [
  {
    id: "FAQ1",
    answer: "Todogo.com ana sayfasında 'Uçak Bileti Ara' seçeneğini kullanarak uçuş detaylarınızı girebilir ve uygun seçenekleri karşılaştırarak rezervasyon yapabilirsiniz. Ödeme işlemleri de sitemiz üzerinden güvenli bir şekilde gerçekleştirilir.",
    question: "Todogo.com üzerinden nasıl uçak bileti satın alabilirim?",
  },
  {
    id: "FAQ2",
    answer: "Todogo.com'da 'Otel Ara' özelliği ile konaklamak istediğiniz şehir, tarih ve diğer tercihlerinizi belirleyebilir, uygun otelleri karşılaştırabilir ve rezervasyonunuzu tamamlayabilirsiniz.",
    question: "Todogo.com üzerinden nasıl otel rezervasyonu yapabilirim?",
  },
  {
    id: "FAQ3",
    answer: "Havaalanı transferi için 'Transfer Hizmeti' bölümünden varış ve ayrılış detaylarınızı ekleyerek transfer rezervasyonunuzu oluşturabilirsiniz. Sizden gelen bilgilerle uygun bir transfer sağlanacaktır.",
    question: "Todogo.com üzerinden nasıl transfer hizmeti alabilirim?",
  },
  {
    id: "FAQ4",
    answer: "Araç Kirala bölümünde araç türü, tarihler ve yer seçeneklerini belirleyerek araç kiralama işleminizi gerçekleştirebilirsiniz. Rezervasyonunuz tamamlandıktan sonra detaylar size e-posta ile gönderilecektir.",
    question: "Todogo.com üzerinden nasıl araç kiralama yapabilirim?",
  },
  {
    id: "FAQ5",
    answer: "Rezervasyonunuzu iptal etmek için hesabınıza giriş yaparak 'Rezervasyonlarım' bölümünden ilgili rezervasyonu seçebilir ve iptal prosedürünü takip edebilirsiniz. İptal politikalarını incelemeyi unutmayın.",
    question: "Todogo.com üzerinden nasıl rezervasyon iptali yapabilirim?",
  },
  {
    id: "FAQ6",
    answer: "Todogo.com'da kredi kartı, banka kartı ve diğer güvenli ödeme seçenekleri bulunmaktadır. Ödeme sırasında kullanıcı bilgileriniz güvenli bir şekilde işlenir.",
    question: "Todogo.com üzerinden nasıl ödeme yapabilirim?",
  },
  {
    id: "FAQ7",
    answer: "Yolculuk sırasında herhangi bir sorunla karşılaşırsanız, 7/24 çalışan müşteri hizmetlerimizle iletişime geçebilirsiniz. İletişim bilgilerimiz sitemizde bulunmaktadır.",
    question: "Todogo.com üzerinden müşteri hizmetlerine nasıl ulaşabilirim?",
  },
];

const FrequentlyAskedQuestions: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  return (
    <div
      id="faq"
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <div className="justify-center text-center mt-40">
        <h1 className="text-3xl md:text-4xl font-semibold">Sıkça Sorulan Sorular</h1>
        <p className="faqSubTitle mt-2 md:mt-5 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">Todogo.com'un sıradaki gezgini, merak ettiğin pek çok sorunun cevabını aşağıda bulabilirsin.</p>
      </div>
      <div className="mb-8 lg:mb-11">
      </div>
      <div className="lg:p-10 grid grid-cols-1 gap-6 rounded-3xl">
        {DEMO_DATA.map((item, index) => (
          <FAQCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;
