import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";

const typeOfAirlines = [
  {
    name: "American Airlines",
  },
  {
    name: "United Airlines",
  },
  {
    name: "Delta Airlines",
  },
  {
    name: "Turkish Airlines",
  }
];
const typeOfStopPoints = [
  {
    name: "Direkt Uçuş",
  },
  {
    name: "Aktarmalı Uçuş",
  },
];
interface TabFiltersProps {
  stopPoints: { name: string }[];
  onStopPointsChange: (points: { name: string }[]) => void;
  airlines: { name: string }[];
  typeOfAirlines: { name: string }[];
  onAirlinesChange: (airlines: { name: string }[]) => void;
  departureTimes: [number, number];
  onDepartureTimesChange: (times: [number, number]) => void;
  arrivalTimes: [number, number];
  onArrivalTimesChange: (times: [number, number]) => void;
  priceRange: [number, number];
  onPriceRangeChange: (range: [number, number]) => void;
  tripTimes: number;
  onTripTimesChange: (time: number) => void;
}

const TabFilters: React.FC<TabFiltersProps> = ({
  stopPoints = [],
  onStopPointsChange,
  airlines = [],
  typeOfAirlines,
  onAirlinesChange,
  departureTimes = [0, 24],
  onDepartureTimesChange,
  arrivalTimes = [0, 24],
  onArrivalTimesChange,
  priceRange = [0, 5000],
  onPriceRangeChange,
  tripTimes = 72,
  onTripTimesChange,
}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [rangePrices, setRangePrices] = useState([0, 5000]);
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const handleChangeStopPoint = (checked: boolean, name: string) => {
    console.log(checked,name);
    const newStopPoints = checked
      ? [...stopPoints, { name }]
      : stopPoints.filter((i) => i.name !== name);
      console.log(newStopPoints);
    onStopPointsChange(newStopPoints);
  };

  const handleChangeAirlines = (checked: boolean, name: string) => {
    const newAirlines = checked
      ? [...airlines, { name }]
      : airlines.filter((i) => i.name !== name);
    onAirlinesChange(newAirlines);
  };


  const renderXClear = (stateUpdater: React.Dispatch<React.SetStateAction<string[]>>) => {
    return (
      <span 
        className="flex items-center justify-center ml-2 cursor-pointer mt-0.5"
        onClick={(e) => {
          e.stopPropagation();
          stateUpdater([]);
        }}
      >
<i className ="las la-times"></i>
      </span>
    );
  };

  const renderTabsStopPoints = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none
               ${open ? "!" : ""}
                ${
                  !!stopPoints.length
                    ? "! bg-neutral-50 dark:bg-black/20"
                    : ""
                }
                `}
            >
              <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
                {stopPoints.length > 0
                  ? `${stopPoints.map(point => point.name).join(" ve ")}`
                  : "Uçuş Tipleri"}
              </span>
              {!stopPoints.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                renderXClear(() => onStopPointsChange([]))
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfStopPoints.map((item) => (
                      <div key={item.name} className="flex items-center justify-between">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={stopPoints.some(point => point.name === item.name)}
                          onChange={(checked) =>
                            handleChangeStopPoint(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {/*<div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        onStopPointsChange([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                    <ButtonThird
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Uygula</span>
                    </ButtonThird>
                  </div>*/}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfAirlines = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none
                ${open ? "!" : ""}
                ${
                  !!airlines.length
                    ? "! bg-neutral-50 dark:bg-black/20"
                    : ""
                }
                `}
            >
              <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
                {airlines.length > 0
                  ? `${airlines.length} Havayolu`
                  : "Havayolları"}
              </span>
              {!airlines.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                renderXClear(() => onAirlinesChange([]))
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfAirlines.map((item) => (
                      <div key={item.name} className="flex items-center justify-between">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={airlines.some(airline => airline.name === item.name)}
                          onChange={(checked) =>
                          {
                            handleChangeAirlines(checked, item.name)
                            airlines = ([...airlines, { name: item.name }])
                            console.log(checked,item.name)
                          }
                          }
                        />
                      </div>
                    ))}
                  </div>
                  {/*<div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        onAirlinesChange([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                      <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                    <ButtonThird
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                      <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Uygula</span>
                    </ButtonThird>
                  </div>*/}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const formatTime = (hour: number) => {
    return hour === 24 ? "23:59" : `${hour.toString().padStart(2, '0')}:00`;
  };

  const renderTabsTimeFlightTab = () => {
    return (
      <div className="space-y-8">
        <div className="space-y-3">
          <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
            Kalkış Zamanı
          </span>
          <div className="space-y-3">
            <div className="flex space-x-2 justify-center">
              <span className="text-xs text-neutral-6000 dark:text-neutral-300">
                {formatTime(departureTimes[0])} - {formatTime(departureTimes[1])}
              </span>
            </div>
            <Slider
              range
              min={0}
              max={24}
              value={departureTimes}
              onChange={(val) => onDepartureTimesChange(val as [number, number])}
              allowCross={false}
            />
          </div>
        </div>
        <div className="space-y-5">
          <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
            Varış Zamanı
          </span>
          <div className="space-y-3">
            <div className="flex space-x-2 justify-center">
              <span className="text-xs text-neutral-6000 dark:text-neutral-300">
                {formatTime(arrivalTimes[0])} - {formatTime(arrivalTimes[1])}
              </span>
            </div>
            <Slider
              range
              min={0}
              max={24}
              defaultValue={arrivalTimes}
              onChange={(val) => onArrivalTimesChange(val as [number, number])}
              allowCross={false}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTabsTimeFlight = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none ${
                open ? "!" : ""
              } ${
                departureTimes[0] !== 0 || departureTimes[1] !== 24 || arrivalTimes[0] !== 0 || arrivalTimes[1] !== 24
                  ? "! bg-neutral-50 dark:bg-black/20"
                  : ""
              }`}
            >
              <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
                {departureTimes[0] !== 0 || departureTimes[1] !== 24 || arrivalTimes[0] !== 0 || arrivalTimes[1] !== 24
                  ? `${formatTime(departureTimes[0])} - ${formatTime(departureTimes[1])} ve ${formatTime(arrivalTimes[0])} - ${formatTime(arrivalTimes[1])}`
                  : "Kalkış ve Varış Zamanı"}
              </span>
              {departureTimes[0] !== 0 || departureTimes[1] !== 24 || arrivalTimes[0] !== 0 || arrivalTimes[1] !== 24 ? (
                renderXClear(() => {
                  onDepartureTimesChange([0, 24]);
                  onArrivalTimesChange([0, 24]);
                })
              ) : (
                <i className="las la-angle-down ml-2"></i>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {renderTabsTimeFlightTab()}
                  </div>
                  {/*<div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => {
                      onDepartureTimesChange([0, 24]);
                      onArrivalTimesChange([0, 24]);
                      close();
                    }} sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl">
                      <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                    <ButtonThird
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                      <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Uygula</span>
                    </ButtonThird>
                  </div>*/}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTripTime = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none
              ${open ? "!" : ""}
                ${
                  tripTimes !== 72
                    ? "! bg-neutral-50 dark:bg-black/20"
                    : ""
                }
                `}
            >
              <span className="text-neutral-6000 dark:text-neutral-300 text-sm">{tripTimes !== 72 ? `${tripTimes} saatten az` : "Uçuş Süresi"}</span>
              {tripTimes !== 72 ? renderXClear(() => onTripTimesChange(72)) : <i className="las la-angle-down ml-2"></i>}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-3">
                      <div className="text-neutral-6000 dark:text-neutral-300 text-sm justify-center">
                        Uçuş Süresi
                      </div>
                      <div className="space-y-3">
                        <span className="text-xs text-neutral-6000 dark:text-neutral-300">{` ${tripTimes} saatten az`}</span>
                        <Slider
                          min={0}
                          max={72}
                          value={tripTimes}
                          onChange={(e) => onTripTimesChange(e as number)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*<div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => {onTripTimesChange(72); close();}} sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl">
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                    <ButtonThird
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Uygula</span>
                    </ButtonThird>
                  </div>*/}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none
              ${open ? "!" : ""}
                ${
                  priceRange[0] !== 0 || priceRange[1] !== 5000
                    ? "! bg-neutral-50 dark:bg-black/20"
                    : ""
                }
                `}
            >
              <span className="text-neutral-6000 dark:text-neutral-300 text-sm">{priceRange[0] !== 0 || priceRange[1] !== 5000 ? `${priceRange[0] + " TL"} - ${priceRange[1] + " TL"}` : "Bilet Fiyatı"}</span>
              {priceRange[0] !== 0 || priceRange[1] !== 5000 ? renderXClear(() => onPriceRangeChange([0, 5000])) : <i className="las la-angle-down ml-2"></i>}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-3">
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm justify-center">Bilet Fiyatı</span>
                      <div className="space-y-3">
                      <div className="text-xs text-xs text-neutral-6000 dark:text-neutral-300">{`${priceRange[0]} TL - ${priceRange[1]} TL`}</div>
                      <Slider
                        range
                        min={0}
                        max={5000}
                        value={priceRange}
                        onChange={(values) => onPriceRangeChange(values as [number, number])}
                        allowCross={false}
                      />
                      </div>
                    </div>
                  </div>
                  {/*<div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={() => {onPriceRangeChange([0, 5000]); close();}} sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl">
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                    <ButtonThird
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 rounded-xl"
                    >
                    <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Uygula</span>
                    </ButtonThird>
                  </div>*/}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderMoreFilterItem = (
    data: {
      name: string;
      description?: string;
      defaultChecked?: boolean;
    }[]
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
      </div>
    );
  };


  const getActiveFilterCount = () => {
    let count = 0;
    

    if (airlines && airlines.length > 0) count++;
    

    if (stopPoints && stopPoints.length > 0) count++;
    

    if (priceRange[0] !== 0 || priceRange[1] !== 5000) count++;
    

    if ((departureTimes[0] !== 0 || departureTimes[1] !== 24) ||
        (arrivalTimes[0] !== 0 || arrivalTimes[1] !== 24)) {
      count++;
    }


    if (tripTimes !== 72) count++;

    console.log('Filter Count Details:', {
      airlines: airlines.length > 0,
      stopPoints: stopPoints.length > 0,
      priceRange: priceRange[0] !== 0 || priceRange[1] !== 5000,
      times: (departureTimes[0] !== 0 || departureTimes[1] !== 24) ||
             (arrivalTimes[0] !== 0 || arrivalTimes[1] !== 24),
      tripTimes: tripTimes !== 72
    });

    return count;
  };

  const renderTabMobileFilter = () => {
    const filterCount = getActiveFilterCount();
    
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-xl border border-neutral-200 dark:border-neutral-700 focus:outline-none cursor-pointer
            ${filterCount > 0 ? "bg-neutral-50 dark:bg-black/20" : ""}`}
          onClick={openModalMoreFilter}
        >
          <span className="text-neutral-6000 dark:text-neutral-300">
            Filtreler {filterCount > 0 ? `(${filterCount})` : ""}
          </span>
          {filterCount > 0 ? (
            renderXClear(() => {
              onAirlinesChange([]);
              onStopPointsChange([]);
              onPriceRangeChange([0, 2000]);
              onDepartureTimesChange([0, 24]);
              onArrivalTimesChange([0, 24]);
              onTripTimesChange(24);
            })
          ) : (
            <i className="las la-angle-down ml-2"></i>
          )}
        </div>

        {/* Modal içeriğini güncelleyelim */}
        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                      Filtreler {getActiveFilterCount() > 0 ? `(${getActiveFilterCount()})` : ""}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* Havayolları */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">Havayolları</h3>
                        <div className="mt-6">
                          {renderMobileAirlines()}
                        </div>
                      </div>

                      {/* Durak Noktaları */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">Uçuş Tipleri</h3>
                        <div className="mt-6">
                          {renderMobileStopPoints()}
                        </div>
                      </div>

                      {/* Fiyat Aralığı */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">Bilet Fiyatı</h3>
                        <div className="mt-6">
                          <div className="relative flex flex-col space-y-5">
                            <div className="space-y-5">
                              <Slider
                                range
                                min={0}
                                max={2000}
                                defaultValue={priceRange}
                                onChange={(values) => onPriceRangeChange(values as [number, number])}
                                allowCross={false}
                              />
                            </div>
                            <div className="flex justify-center space-x-5">
                            <span className="text-sm text-neutral-6000 dark:text-neutral-300">{`${priceRange[0]} TL - ${priceRange[1]} TL`}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Uçuş Süresi */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium text-center">Uçuş Süresi</h3>
                        <div className="mt-6">
                          <div className="relative flex flex-col space-y-5">
                            <div className="space-y-5">
                              <Slider
                                min={0}
                                max={72}
                                value={tripTimes}
                                onChange={(value) => onTripTimesChange(value as number)}
                              />
                            </div>
                            <div className="flex justify-center space-x-5">
                            <span className="text-neutral-6000 dark:text-neutral-300 text-sm">
  {`${tripTimes} saatten az`}
</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Uçuş Zamanı */}
                      <div className="py-7">
                        <h3 className="text-xl font-mediu text-center">Uçuş Zamanı</h3>
                        <div className="mt-6">
                          <div className="relative flex flex-col space-y-8">
                            <div>
                              <span className="block text-sm text-neutral-500 dark:text-neutral-400 mb-2 text-center">
                                Kalkış Zamanı
                              </span>
                              <Slider
                                range
                                min={0}
                                max={24}
                                value={departureTimes}
                                onChange={(values) => onDepartureTimesChange(values as [number, number])}
                                allowCross={false}
                              />
            <div className="flex space-x-2 justify-center mt-5">
              <span className="text-sm text-neutral-6000 dark:text-neutral-300">
                {formatTime(departureTimes[0])} - {formatTime(departureTimes[1])}
              </span>
            </div>
                            </div>
                            <div>
                              <span className="block text-sm text-neutral-500 dark:text-neutral-400 mb-2 text-center">
                                Varış Zamanı
                              </span>
                              <Slider
                                range
                                min={0}
                                max={24}
                                value={arrivalTimes}
                                onChange={(values) => onArrivalTimesChange(values as [number, number])}
                                allowCross={false}
                              />
            <div className="flex space-x-2 justify-center mt-5">
              <span className="text-sm text-neutral-6000 dark:text-neutral-300">
                {formatTime(arrivalTimes[0])} - {formatTime(arrivalTimes[1])}
              </span>
            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sadece Temizle butonu */}
                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800">
                    <ButtonThird 
                      onClick={() => {
                        onAirlinesChange([]);
                        onStopPointsChange([]);
                        onPriceRangeChange([0, 5000]);
                        onDepartureTimesChange([0, 24]);
                        onArrivalTimesChange([0, 24]);
                        onTripTimesChange(72);
                      }} 
                      sizeClass="w-full px-4 py-2 sm:px-5"
                    >
                      <span className="text-neutral-6000 dark:text-neutral-300 text-sm">Temizle</span>
                    </ButtonThird>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderMobileAirlines = () => {
    const selectedAirlines = airlines.map(air => air.name);
    
    return (
      <div className="relative flex flex-col space-y-5">
        {typeOfAirlines.map((item) => (
          <div key={item.name} className="flex items-center justify-between">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={selectedAirlines.includes(item.name)}
              onChange={(checked) => {
                handleChangeAirlines(checked, item.name);
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderMobileStopPoints = () => {
    const selectedStopPoints = stopPoints.map(point => point.name);
    
    return (
      <div className="relative flex flex-col space-y-5">
        {typeOfStopPoints.map((item) => (
          <div key={item.name} className="flex items-center justify-between">
            <Checkbox
              name={item.name}
              label={item.name}
              defaultChecked={selectedStopPoints.includes(item.name)}
              onChange={(checked) => {
                handleChangeStopPoint(checked, item.name);
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4 justify-center">
      <div className="hidden lg:flex space-x-4">
        {renderTabsTypeOfAirlines()}
        {renderTabsStopPoints()}
        {renderTabsPriceRage()}
        {renderTabsTripTime()}
        {renderTabsTimeFlight()}
      </div>
      <div className="flex lg:hidden space-x-4">
        {renderTabMobileFilter()}
      </div>
    </div>
  );
};

export default TabFilters;