"use client";

import React, { FC } from "react";
import LocationInput from "../LocationInput";
import RentalCarDatesRangeInput from "./RentalCarDatesRangeInput";

export interface RentalCarSearchFormProps {}

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = () => {
  return (
    <form className="w-full relative mt-8 rounded-[40px] xl:rounded-[49px] shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
      <div className={`relative flex flex-row`}>
        <LocationInput
          type="car"
          placeHolder="Nereden"
          desc="Nereden gitmek istiyorsunuz?"
          className="flex-1"
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <LocationInput
          type="car"
          placeHolder="Nereye"
          desc="Nereye gitmek istiyorsunuz?"
          className="flex-1"
          divHideVerticalLineClass="-inset-x-0.5"
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <RentalCarDatesRangeInput className="flex-1" />
      </div>
    </form>
  );
};

export default RentalCarSearchForm;
