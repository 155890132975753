import ButtonSecondary from "shared/Button/ButtonSecondary";
import React from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useNavigate } from "react-router-dom";

const Page404: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Bir önceki sayfaya dön
  };

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>TODOGO | 404</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            Üzgünüz, aradığınız sayfa bulunamadı.{" "}
          </span>
          <div className="pt-8 flex justify-center space-x-4">
            <ButtonSecondary href="/">Ana Sayfa'ya Dön</ButtonSecondary>
            <ButtonSecondary onClick={handleGoBack}>Bir Önceki Sayfaya Dön</ButtonSecondary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page404;
