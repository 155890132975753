import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { useEffect, useState } from "react";
import { FC } from "react";

export interface GuestsInputProps {
  defaultValue?: GuestsObject;
  onChange?: (data: GuestsObject) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  className = "",
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue?.adults || 1
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue?.childrens || 0
  );

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue?.adults || 1);
  }, [defaultValue?.adults]);

  useEffect(() => {
    setGuestChildrenInputValue(defaultValue?.childrens || 0);
  }, [defaultValue?.childrens]);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      adults: guestAdultsInputValue,
      children: guestChildrenInputValue,
    };
    if (type === "adults") {
      setGuestAdultsInputValue(value);
      newValue.adults = value;
    }
    if (type === "childrens") {
      setGuestChildrenInputValue(value);
      newValue.children = value;
    }
    onChange && onChange(newValue);
  };

  return (
    <div className={`flex flex-col relative ${className}`}>
      <span className="mb-5 block font-semibold text-xl sm:text-2xl"></span>
      <NcInputNumber
        className="w-full"
        defaultValue={guestAdultsInputValue}
        onChange={(value) => handleChangeData(value, "adults")}
        min={1}
        max={7}
        label="Yetişkin"
        desc="17 yaş ve üzeri"
      />
      <NcInputNumber
        className="w-full mt-6"
        defaultValue={guestChildrenInputValue}
        onChange={(value) => handleChangeData(value, "childrens")}
        max={7}
        label="Çocuk"
        desc="0 ve 17 yaş arası"
      />
    </div>
  );
};

export default GuestsInput;
