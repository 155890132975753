import React, { FC, useState } from "react";

export interface FAQCardProps {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    answer: string;
    question: string;
  };
}

const FAQCard: FC<FAQCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const renderDetailTop = () => {
    return (
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex my-5 md:my-0">
            <div className="space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {data.answer}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return <div className="rounded-2xl">{renderDetailTop()}</div>;
  };

  return (
    <div
      className={`nc-FAQCardCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
      dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FAQCard"
    >
      <div className={`pr-20 relative  ${className}`} data-nc-id="FAQCard">
        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 rounded-full flex items-center justify-center cursor-pointer bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-6000 dark:hover:border-neutral-500 hover:border-neutral-300${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>
        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          <div className="block lg:hidden space-y-1">
            <div className="flex font-medium">
              <div>
                <span>{data.question}</span>
              </div>
            </div>
          </div>
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">{data.question}</div>
          </div>
        </div>
      </div>
      {renderDetail()}
    </div>
  );
};

export default FAQCard;
