import React from 'react';
import { Helmet } from 'react-helmet-async';

const KvkkGizlilikCerez: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>KVKK & Gizlilik & Çerez | TODOGO</title>
      </Helmet>
      
      <div className="container mx-auto px-4 py-16 lg:py-24">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl md:text-4xl font-semibold mb-8 text-center">KVKK, Gizlilik ve Çerez</h1>
          
          <div className="space-y-8 text-neutral-600 dark:text-neutral-400">
            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                1. Genel Bilgilendirme
              </h2>
              <p>
                Todogo.com olarak, kullanıcılarımızın kişisel verilerinin korunmasını, gizliliğinin sağlanmasını ve veri işleme süreçlerinin şeffaf bir şekilde yürütülmesini taahhüt ediyoruz. Bu sayfa, kişisel verilerinizin nasıl toplandığını, işlendiğini ve saklandığını açıklamaktadır. 6698 sayılı Kişisel Verilerin Korunması Kanunu ("KVKK") çerçevesinde, veri sorumlusu sıfatıyla gerekli bilgilendirme yapılmaktadır.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                2. Kişisel Verilerin İşlenme Amaçları
              </h2>
              <p className="mb-4">Todogo.com kullanıcılarının kişisel verileri, aşağıdaki amaçlarla işlenmektedir:</p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Rezervasyon ve Satış İşlemleri: Uçak bileti, otel rezervasyonu gibi hizmetlerin sunulması.</li>
                <li>Ödeme ve Faturalandırma: Banka/kredi kartı işlemlerinin gerçekleştirilmesi ve fatura oluşturulması.</li>
                <li>Müşteri İletişimi: Kullanıcılara destek hizmetleri sağlanması ve gerekli bilgilendirmelerin yapılması.</li>
                <li>Pazarlama ve Kampanyalar: Kullanıcı onayı doğrultusunda tanıtım ve reklam faaliyetleri yürütülmesi.</li>
                <li>Yasal Yükümlülüklerin Yerine Getirilmesi: Yetkili kurumlarca talep edilen bilgilerin sağlanması.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                3. Kişisel Verilerin Toplanma Yöntemi
              </h2>
              <p>
                Kişisel verileriniz; Todogo.com web sitesi, mobil uygulamaları, çağrı merkezi ve e-posta kanalları gibi çeşitli platformlar üzerinden, otomatik ya da manuel yollarla toplanmaktadır.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                4. Veri Güvenliği
              </h2>
              <ul className="list-disc pl-5 space-y-2">
                <li>Todogo.com, kullanıcıların veri güvenliğini sağlamak için 256-bit SSL şifreleme ve kurumsal güvenlik standartlarına sahip teknolojiler kullanmaktadır.</li>
                <li>Kullanıcıların banka/kredi kartı bilgileri, PCI DSS sertifikalı bir altyapı üzerinden saklanmakta ve yalnızca yetkili ödeme kuruluşları ile paylaşılmaktadır.</li>
                <li>Ödeme ve fatura bilgileriniz Todogo.com'un veri tabanında saklanmaz; yalnızca işlemler esnasında kullanılır.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                5. Kişisel Verilerin Aktarımı
              </h2>
              <p className="mb-4">
                Kişisel verileriniz, KVKK ve ilgili mevzuat hükümleri doğrultusunda aşağıdaki taraflarla paylaşılabilir:
              </p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Havayolu şirketleri, otel sağlayıcılar ve diğer hizmet sağlayıcılar.</li>
                <li>Hukuki ve idari merciler.</li>
                <li>Todogo.com'un yasal yükümlülüklerini yerine getirmekle sorumlu iş ortakları.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                6. Çerez (Cookie) Kullanımı
              </h2>
              <p>
                Todogo.com, kullanıcı deneyimini iyileştirmek ve hizmet kalitesini artırmak için çerezler kullanmaktadır. Çerezler, kullanıcıların oturum bilgilerini ve tercihlerini saklayarak, bir sonraki ziyaretlerinde kolaylık sağlar. Kullanıcılar, tarayıcı ayarlarını değiştirerek çerez kullanımını engelleyebilir.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                7. Veri Sahiplerinin Hakları
              </h2>
              <p className="mb-4">KVKK'nın 11. maddesi kapsamında, veri sahipleri aşağıdaki haklara sahiptir:</p>
              <ul className="list-disc pl-5 space-y-2">
                <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme.</li>
                <li>İşlenme amacı ve amaca uygun kullanılıp kullanılmadığını öğrenme.</li>
                <li>Verilerinizin düzeltilmesini, silinmesini veya anonim hale getirilmesini talep etme.</li>
                <li>İşlenen verilerle ilgili bilgilere erişim ve itiraz hakkı.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                8. İletişim
              </h2>
              <p>
                KVKK ile ilgili her türlü soru ve talepleriniz için bizimle iletişime geçebilirsiniz:<br />
                E-posta: marketing@todogo.com
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default KvkkGizlilikCerez;