import { FC, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";
import ButtonSecondary from "shared/Button/ButtonSecondary";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {
  data: StayDataType[];
  formStayData?: any;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  data,
  formStayData
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showMapModal, setShowMapModal] = useState(false);

  const handleOpenModal = () => {
    setShowMapModal(true);
    setCurrentHoverID(-1);  // Modal açıldığında hover ID'sini sıfırla
  };

  const handleCloseModal = () => {
    setShowMapModal(false);
  };

  const handleStaySelect = (data: StayDataType & { selectedPackage: string | null }) => {
    console.log(data);
  };

  return (
    <div>

        
          <Heading2 
            heading={formStayData.stayLocation}
            subHeading={
              <span className="block items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center mt-7">
                <span>{formStayData.stayDates.startDate} - {formStayData.stayDates.endDate}</span>
                <span className="mx-2">·</span>
                {formStayData.stayGuests.totalRooms} Oda
                <span className="mx-2">·</span>
                {formStayData.stayGuests.totalGuests} Misafir
              </span>
            }
          />



          <div className="mb-8 lg:mb-11">
            <TabFilters />
          </div>
          <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl">
          <div className="grid grid-cols-1 gap-8">
            {data.map((stay) => (
              <StayCardH key={stay.id} data={stay} formStayData={formStayData} onSelect={handleStaySelect} />
            ))}
          </div>
          </div>
          <div className="flex mt-16 justify-center items-center">
            <Pagination />
          </div>
          <div className="flex justify-center mt-8">
            <ButtonSecondary onClick={handleOpenModal}>
              Haritada Göster
            </ButtonSecondary>
          </div>


     
      

      {/* Map Modal */}
      {showMapModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" onClick={handleCloseModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div 
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full"
              onClick={(e) => e.stopPropagation()}  // Modalın içine tıklamak, modalı kapatmayacak
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Harita
                    </h3>
                    <div className="mt-2">
                      <div className="h-[70vh] w-full">
                        <MapContainer
                          currentHoverID={currentHoverID}
                          DEMO_DATA={DEMO_STAYS}
                          listingType="stay"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleCloseModal}
                >
                  Kapat
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
