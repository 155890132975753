import React, { Fragment, useState, useEffect } from "react";
import { FC } from "react";
import DatePicker from "react-datepicker";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import ButtonSubmit from "../ButtonSubmit";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { tr } from "date-fns/locale";

export interface FlightDateRangeInputProps {
  className?: string;
  fieldClassName?: string;
  hasButtonSubmit?: boolean;
  selectsRange?: boolean;
  onChange?: (dates: {
    startDate: Date;
    endDate: Date | null;
  }) => void;
  onSubmit?: (event: React.FormEvent) => void;
  startDate?: Date;
  endDate?: Date | null;
}

const FlightDateRangeInput: FC<FlightDateRangeInputProps> = ({
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  hasButtonSubmit = true,
  selectsRange = true,
  onChange,
  onSubmit,
  startDate: initialStartDate,
  endDate: initialEndDate,
}) => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date>(initialStartDate || today);
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate || null);

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(initialStartDate);
    }
    if (initialEndDate) {
      setEndDate(initialEndDate);
    }
  }, [initialStartDate, initialEndDate]);

  const onChangeRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start || today);
    setEndDate(end);

    onChange?.({
      startDate: start || today,
      endDate: end,
    });
  };

  const handleClearDates = () => {
    setStartDate(today);
    setEndDate(selectsRange ? today : null);

    onChange?.({
      startDate: today,
      endDate: selectsRange ? today : null,
    });
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("tr-TR", {
              month: "long",
              day: "2-digit",
            }) || "Tarih ekleyin"}
            {selectsRange && endDate
              ? " - " +
                endDate?.toLocaleDateString("tr-TR", {
                  month: "long",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {selectsRange ? "Gidiş ve Dönüş Tarihiniz" : "Gidiş Tarihiniz"}
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <Popover className={`FlightDateRangeInput relative flex ${className}`}>
        {({ open }) => (
          <>
            <div
              className={`flex-1 z-10 flex items-center focus:outline-none ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none `}
                onClickCapture={() => document.querySelector("html")?.click()}
              >
                {renderInput()}

                {startDate && open && (
                  <ClearDataButton onClick={handleClearDates} />
                )}
              </Popover.Button>
              {hasButtonSubmit && (
                <div className="pr-2 xl:pr-4">
                  <ButtonSubmit href="/listing-flights" onClick={onSubmit} isDisabled={false} />
                </div>
              )}
            </div>

            {open && (
              <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
            )}

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-1/2 z-20 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                  {selectsRange ? (
                    <DatePicker
                      selected={startDate}
                      onChange={onChangeRangeDate}
                      startDate={startDate}
                      endDate={endDate || undefined}
                      selectsRange
                      monthsShown={2}
                      showPopperArrow={false}
                      inline
                      locale={tr}
                      minDate={today}
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                    />
                  ) : (
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        const selectedDate = date || today;
                        setStartDate(selectedDate);
                        onChange?.({
                          startDate: selectedDate,
                          endDate: null,
                        });
                      }}
                      monthsShown={2}
                      showPopperArrow={false}
                      inline
                      locale={tr}
                      minDate={today}
                      renderCustomHeader={(p) => (
                        <DatePickerCustomHeaderTwoMonth {...p} />
                      )}
                      renderDayContents={(day, date) => (
                        <DatePickerCustomDay dayOfMonth={day} date={date} />
                      )}
                    />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default FlightDateRangeInput;
