/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from "react";
import LocationInput from "../LocationInput";
import GuestsInput, { GuestsObject } from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useNavigate } from "react-router-dom";
import { parse } from 'date-fns';

export interface StaySearchFormProps {
  formStayData?: any;
}

const StaySearchForm: FC<StaySearchFormProps> = ({ formStayData }) => {

  const navigate = useNavigate();

  const [stayLocation, setStayLocation] = useState("");
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [guests, setGuests] = useState([{ adults: 1, children: 0 }]);
  const [stayError, setStayError] = useState(false);

  const getTodayDate = () => {
    const today = new Date();
    return today.toLocaleDateString("tr-TR");
  };

  useEffect(() => {
    if (formStayData) {
      setStayLocation(formStayData.stayLocation);

      if (formStayData.stayDates) {
        if (formStayData.stayDates.startDate) {
          const startDateObj = parse(formStayData.stayDates.startDate, 'dd.MM.yyyy', new Date());
          setStartDate(startDateObj);
        }
        if (formStayData.stayDates.endDate) {
          const endDateObj = parse(formStayData.stayDates.endDate, 'dd.MM.yyyy', new Date());
          setEndDate(endDateObj);
        }
      }

      // Guests state'ini güncelle
      if (formStayData.stayGuests && formStayData.stayGuests.guests) {
        setGuests(formStayData.stayGuests.guests);
      }
    }
  }, [formStayData]);

  const handleStayChange = (value: string) => {
    setStayLocation(value);
    if (value === "") {
      setStayError(false);
    } else {
      setStayError(false);
    }
  };

  const handleGuestsChange = (newGuests: GuestsObject[]) => {
    setGuests(newGuests);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let hasError = false;

    if (!stayLocation) {
      setStayError(true);
      hasError = true;
    } else {
      setStayError(false);
    }

    if (hasError) {
      return;
    }

    const todayDate = getTodayDate();

    const formStayData = {
      stayLocation,
      stayDates: {
        startDate: startDate
          ? startDate.toLocaleDateString("tr-TR")
          : todayDate,
        endDate: endDate ? endDate.toLocaleDateString("tr-TR") : todayDate,
      },
      stayGuests: {
        guests,
        adultGuests: guests.reduce((total, room) => total + room.adults, 0),
        childrenGuests: guests.reduce(
          (total, room) => total + room.children,
          0
        ),
        totalGuests: guests.reduce(
          (total, room) => total + room.adults + room.children,
          0
        ),
        totalRooms: guests.length,
      },
    };

    // Burada log ekliyoruz
    console.log("Otel arama verisi:", formStayData);

    navigate("/listing-stay-map", { state: formStayData });
  };

  const renderForm = () => {
    return (
      <form
        className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 "
        onSubmit={handleSubmit}
      >
        <LocationInput
          type="hotel"
          className="flex-[1.5]"
          placeHolder="Nereye"
          desc={
            stayError
              ? "Lütfen bu alanı doldurun."
              : "Nereye gitmek istiyorsunuz?"
          }
          value={stayLocation}
          onChange={handleStayChange}
          hasError={stayError}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput
          className="flex-1"
          startDate={startDate}
          endDate={endDate}
          onChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-1"
          guests={guests}
          onGuestsChange={handleGuestsChange}
          onSubmit={handleSubmit}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
