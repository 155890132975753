import DatePicker from "react-datepicker";
import React, { FC, useState, useEffect } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { tr } from "date-fns/locale";

export interface StayDatesRangeInputProps {
  className?: string;
  selectsRange?: boolean;
  dateSelectCallback: (dates: [Date | null, Date | null] | Date | null) => void;
  dropOffLocationType: string;
  isHotel: boolean;
  isTransfer: boolean;
}

const getTitle = (
  isHotel: boolean,
  dropOffLocationType: string,
  isTransfer: boolean
) => {
  if (isHotel) {
    return "Giriş ve Çıkış Tarihiniz";
  }
  if (isTransfer) {
    return "Gidiş Tarihiniz";
  }
  return dropOffLocationType === "Tek Yön"
    ? "Gidiş Tarihiniz"
    : "Gidiş ve Dönüş Tarihiniz";
};

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  selectsRange = true,
  dateSelectCallback,
  dropOffLocationType,
  isHotel,
  isTransfer,
}) => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | null>(today);
  const [endDate, setEndDate] = useState<Date | null>(
    selectsRange ? today : null
  );

  const onChangeRangeDate = (
    dates: [Date | null, Date | null] | Date | null
  ) => {
    if (selectsRange) {
      const [start, end] = dates as [Date | null, Date | null];
      setStartDate(start);
      setEndDate(end);
    } else {
      const start = dates as Date | null;
      setStartDate(start);
    }
    dateSelectCallback(dates as [Date | null, Date | null] | Date | null);
  };

  useEffect(() => {
    const today = new Date();
    if (selectsRange) {
      setStartDate(today);
      setEndDate(today);
    } else {
      setStartDate(today);
      setEndDate(today);
    }
  }, [selectsRange]);

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-base sm:text-2xl">
          {getTitle(isHotel, dropOffLocationType, isTransfer)}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className}`}
      >
        {selectsRange ? (
          <DatePicker
            selected={startDate}
            onChange={onChangeRangeDate}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            selectsRange
            monthsShown={2}
            showPopperArrow={false}
            inline
            locale={tr}
            minDate={today}
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        ) : (
          <DatePicker
            selected={startDate}
            onChange={onChangeRangeDate}
            monthsShown={2}
            showPopperArrow={false}
            inline
            locale={tr}
            minDate={today}
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
