import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet-async";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "./SectionHero";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  const features = [
    {
      icon: <i className="las la-globe text-4xl"></i>,
      title: "TODOGO",
      desc: "Todogo.com'da biz, seyahat etmenin sadece bir yerden bir yere gitmek olmadığını, aynı zamanda yeni deneyimler kazanmak, keşifler yapmak ve anılar biriktirmek olduğuna inanıyoruz. Her müşterimizin benzersiz hikayeler yaratmasına yardımcı olmak için kişiselleştirilmiş seyahat planları sunuyoruz. Uzman ekibimiz, her detayın mükemmel olduğundan emin olmak adına titizlikle çalışırken, müşterilerimize eşsiz bir tatil deneyimi sağlamak için geniş bir hizmet yelpazesi sunuyoruz. Hayallerinizdeki tatilin kapılarını aralamak için buradayız; çünkü biz, Todogo olarak, her anın değerli olduğuna ve keşfedilmeye değer bir dünyada yaşadığımıza inanıyoruz."
    },
    {
      icon: <i className="las la-eye text-4xl"></i>,
      title: "Vizyonumuz",
      desc: "Todogo olarak vizyonumuz, seyahat endüstrisinde yenilikçilik ve mükemmeliyetçilikle öne çıkmak; müşterilerimize unutulmaz anılar ve keşifler sunacak, sınırları zorlayan deneyimler yaratmaktır. En yüksek müşteri memnuniyetini hedefleyerek, seyahat etmenin her yönüyle bireysel ihtiyaçlara duyarlı, erişilebilir ve sürdürülebilir çözümler sunmayı amaçlıyoruz."
    },
    {
      icon: <i className="las la-rocket text-4xl"></i>,
      title: "Misyonumuz",
      desc: "Her bir müşterimizin dünyayı kendi benzersiz perspektifleriyle keşfetmesine olanak tanıyarak, seyahat deneyimlerini kişiselleştirmek. Todogo, en iyi hizmeti, en uygun fiyatlarla sunarak, herkesin hayallerindeki tatili gerçekleştirebilmesi için çalışır. Müşterilerimizin güvenliği, konforu ve memnuniyeti önceliğimizdir; bu yüzden onlara sadece bir seyahat değil, bir yaşam tarzı sunuyoruz."
    }
  ];

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>TODOGO | Hakkımızda</title>
      </Helmet>

      <div className="container mt-20 mb-20">
        <div className="flex justify-center">
          <img src={rightImg} alt="TODOGO" className="w-auto h-auto" />
        </div>
      </div>

      <div className="container mt-20 mb-20">
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Biz Kimiz kartı */}
            <div className="md:col-span-1 lg:col-span-2">
              <div 
                className="relative flex flex-col items-center p-8 rounded-3xl bg-white dark:bg-neutral-800 
                  shadow-xl dark:shadow-2xl hover:shadow-2xl dark:hover:shadow-3xl 
                  transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="mb-4 p-4 bg-neutral-50 dark:bg-neutral-800 rounded-full">
                  {features[0].icon}
                </div>
                <h3 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                  {features[0].title}
                </h3>
                <span className="text-neutral-500 dark:text-neutral-400 text-center">
                  {features[0].desc}
                </span>
              </div>
            </div>
            {features.slice(1).map((item, index) => (
              <div 
                key={index}
                className="relative flex flex-col items-center p-8 rounded-3xl bg-white dark:bg-neutral-800 
                  shadow-xl dark:shadow-2xl hover:shadow-2xl dark:hover:shadow-3xl 
                  transform hover:-translate-y-1 transition-all duration-300"
              >
                <div className="mb-4 p-4 bg-neutral-50 dark:bg-neutral-800 rounded-full">
                  {item.icon}
                </div>
                <h3 className="text-xl font-semibold text-neutral-900 dark:text-neutral-100 mb-4">
                  {item.title}
                </h3>
                <span className="text-neutral-500 dark:text-neutral-400 text-center">
                  {item.desc}
                </span>
              </div>
            ))}
          </div>
      </div>

    </div>
  );
};

export default PageAbout;
