import React from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet-async";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionMagazine6 from "./SectionMagazine6";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import rightImg from "images/about-hero-right.png";

// DEMO DATA
const POSTS = DEMO_POSTS;

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_POSTS = POSTS.filter((_, i) => i >= 0 && i < 8);
//

const BlogPage: React.FC = () => {
  return (
    <div className="nc-BlogPage overflow-hidden relative">
      
      <Helmet>
        <title>TODOGO | Blog</title>
      </Helmet>

      <div className="container mt-20 mb-20">
        <div className="flex justify-center">
          <img src={rightImg} alt="TODOGO" className="w-auto h-auto" />
        </div>
      </div>

      <div className="container mt-20 mb-20">
        <SectionAds />
        {/* === SECTION 1 === */}
        <div className="mt-20 mb-20">
          <SectionMagazine5 posts={MAGAZINE1_POSTS} />
        </div>
        <SectionAds />
        <div className="mt-20 mb-20">
          <SectionMagazine6 posts={MAGAZINE1_POSTS} />
        </div>

        {/* === SECTION 1 === */}
        <SectionAds />

        {/* === SECTION 8 === */}
        <SectionLatestPosts className="mt-20 mb-20" />

        {/* === SECTION 1 === */}
      </div>
      
    </div>
  );
};

export default BlogPage;
