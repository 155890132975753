import React, { FC, SelectHTMLAttributes } from "react";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  sizeClass?: string;
}

const Select: FC<SelectProps> = ({
  className = "",
  sizeClass = "h-11",
  children,
  ...args
}) => {
  return (
    <select
      className={`nc-Select ${sizeClass} ${className} block w-full border-neutral-200 focus:border-neutral-200 focus:ring-neutral-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-neutral-200 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-2xl text-sm font-normal h-11 px-4 py-3`}
      {...args}
    >
      {children}
    </select>
  );
};

export default Select;
