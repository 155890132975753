import React, { Fragment, useState, FC, useEffect } from "react";
import { Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { tr } from "date-fns/locale";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  onChange?: (dates: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  }) => void;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding--small ]",
  onChange,
}) => {
  const today = new Date();
  const [startDate, setStartDate] = useState<Date | undefined>(today);
  const [endDate, setEndDate] = useState<Date | undefined>(today);

  useEffect(() => {
    setStartDate(today);
    setEndDate(today);
  }, []);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start || undefined);
    setEndDate(end || undefined);

    if (onChange) {
      onChange({
        startDate: start || undefined,
        endDate: end || undefined,
      });
    }
  };

  const handleClearDates = () => {
    setStartDate(today);
    setEndDate(today);
    if (onChange) {
      onChange({ startDate: today, endDate: today });
    }
  };

  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  const renderInput = () => {
    return (
      <>
        <div className="flex-grow text-left">
          <span className="block xl:text-base font-semibold">
            {startDate?.toLocaleDateString("tr-TR", {
              month: "long",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
                endDate?.toLocaleDateString("tr-TR", {
                  month: "long",
                  day: "2-digit",
                })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Giriş ve Çıkış Tarihiniz"}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      ref={refContainer}
      className={`StayDatesRangeInput z-10 relative flex ${className}`}
    >
      <>
        <div
          className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${
            isOpen ? "nc-hero-field-focused--2" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {renderInput()}
          {startDate && isOpen && (
            <ClearDataButton onClick={handleClearDates} />
          )}
        </div>

        {isOpen && (
          <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <div className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
            <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
              <DatePicker
                selected={startDate}
                onChange={onChangeDate}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                monthsShown={2}
                showPopperArrow={false}
                inline
                locale={tr}
                minDate={today}
                renderCustomHeader={(p) => (
                  <DatePickerCustomHeaderTwoMonth {...p} />
                )}
                renderDayContents={(day, date) => (
                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                )}
              />
            </div>
          </div>
        </Transition>
      </>
    </div>
  );
};

export default StayDatesRangeInput;
