import React, { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";

export interface SectionGridFilterCardProps {
  className?: string;
  data: StayDataType[];
  formStayData?: any;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  formStayData
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2 
        heading={formStayData.stayLocation}
        subHeading={
          <span className="block items-center text-base md:text-lg text-neutral-500 dark:text-neutral-400 text-center mt-7">
            <span>{formStayData.stayDates.startDate} - {formStayData.stayDates.endDate}</span>
            <span className="mx-2">·</span>
            {formStayData.stayGuests.totalRooms} Oda
            <span className="mx-2">·</span>
            {formStayData.stayGuests.totalGuests} Misafir
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>

      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl">
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay) => (
     <StayCard key={stay.id} data={stay} formStayData={formStayData} />
   ))}
        </div>
      </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
