import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardProps {
  className?: string;
  data?: StayDataType;
  size?: "default" | "small";
  formStayData?: any;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  formStayData,
}) => {
  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    bedrooms,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    stayPackage,
  } = data;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<string | null>(null);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={galleryImgs}
          href={href}
        />
        <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />
        {saleOff && <SaleOffBadge className="absolute left-3 top-3" />}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {listingCategory.name} · {bedrooms} beds
          </span>
          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {price}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span>
          {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )}
        </div>
      </div>
    );
  };

  const renderPackageDetails = (packageName: string, packageData: any) => {
    return (
      <div className="flex flex-col h-full">
        <div className="flex-grow">
          <h3 className="font-medium text-lg">{packageName}</h3>
          <ul className="text-sm">
            {Object.entries(packageData).map(([key, value]) => (
              <li key={key} className="text-sm text-neutral-500 font-normal mt-2">
                <i className={`las la-${key.toLowerCase()} me-2`}></i>
                {String(value)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const renderPackages = () => {
    if (!stayPackage) return null;

    return (
      <div className="package-selection-area mt-4 border-t border-neutral-100 dark:border-neutral-800 pt-4">
        <div className="grid grid-cols-3 gap-4">
          {Object.entries(stayPackage).map(([packageName, packageData]) => (
            <div
              key={packageName}
              className={`p-4 rounded-3xl border cursor-pointer ${
                selectedPackage === packageName
                  ? "border-neutral-100 bg-neutral-50 dark:bg-black/20 dark:border-neutral-800"
                  : "border-neutral-100 dark:bg-neutral-900 dark:border-neutral-800"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedPackage(packageName);
              }}
            >
              {renderPackageDetails(packageName, packageData)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleCardClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if ((e.target as HTMLElement).closest('.package-selection-area') || (e.target as HTMLElement).closest('.book-now-button')) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const handleCheckoutClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    console.log("Checkout'a yönlendiriliyor...");
    // Burada checkout sayfasına yönlendirme yapabilirsiniz
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <div onClick={handleCardClick}>
        {renderSliderGallery()}
        {renderContent()}
      </div>
      {isOpen && stayPackage && renderPackages()}
      <button
        className="book-now-button absolute bottom-4 right-4 bg-primary-6000 text-white px-4 py-2 rounded-lg"
        onClick={handleCheckoutClick}
      >
        Book Now
      </button>
    </div>
  );
};

export default StayCard;
